import React from "react";
import { MsalContext } from "@azure/msal-react";
import { PersonnelService } from "_services";
import { App } from "App";
import * as Sentry from "@sentry/react";

class RightsGuard extends React.Component {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {};
    this.hasRight = this.hasRight.bind(this);
    this.hasRightCompteTier = this.hasRightCompteTier.bind(this);
    this.getPersonnelConnecte = this.getPersonnelConnecte.bind(this);
  }

  componentDidMount() {
    this.setState({ personnelConnecte: this.props.personnelConnecte });
    Sentry.setUser({ email: this.props.personnelConnecte.email_Pro });
    if (!this.props.personnelConnecte) {
      const instance = this.context.instance;

      PersonnelService.getByOid(
        instance.getActiveAccount()?.idTokenClaims?.["oid"]
      )
        .then((response) => {
          this.setState({ personnelConnecte: response.data });
          Sentry.setUser({ email: response.data.email_Pro });
        })
        .catch(() =>
          setTimeout(
            () =>
              App.Toaster.current?.createToast({
                body: "La connexion a échouée : l'OID du user connecté ne correspond à personne dans la table personnel",
                header: "Echec",
                type: "failure",
              }),
            3000
          )
        );
    }
  }

  hasRight(module, onglet) {
    const instance = this.context.instance;

    const account = instance.getActiveAccount();
    if (account && account.idTokenClaims["groups"] && this.props.droits) {
      let rightLine = this.props.droits.filter(
        (line) => line.module == module && line.onglet == onglet
      )[0];
      if (rightLine) {
        let groups = account.idTokenClaims["groups"];
        return rightLine[groups[0]?.toLowerCase()];
      }
    }
    return "NA";
  }

  hasRightCompteTier() {
    return this.hasRight("Compte Tiers", "Compte Tiers") == "RW";
  }

  isPersonne(oidToTest) {
    const instance = this.context.instance;

    const account = instance.getActiveAccount();

    if (account && account.idTokenClaims["oid"] && oidToTest) {
      if (oidToTest == account.idTokenClaims["oid"]) return "RW";
    }
    return "NA";
  }

  isManager(initialesResp) {
    if (this.getPersonnelConnecte().initiales == initialesResp) return "RO";
    return "NA";
  }

  getOid() {
    return this.context.instance.getActiveAccount()?.idTokenClaims?.["oid"];
  }

  getRole() {
    return this.context.instance.getActiveAccount()?.idTokenClaims?.[
      "groups"
    ]?.[0];
  }

  isResponsable() {
    let role = this.getRole();
    return role?.startsWith("R") || role?.startsWith("D") || role === "SET";
  }

  isRH() {
    let role = this.getRole();
    return role === "RRH" || role === "ARH" || role === "DDI";
  }

  getPersonnelConnecte() {
    return this.state?.personnelConnecte;
  }

  render() {
    return (
      <>
        {React.Children.map(this.props.children, (child, index) => {
          return React.cloneElement(child, {
            droit: index == 0 ? this.hasRight("Admin", "General") : null,
            personnel: index == 0 ? this.getPersonnelConnecte() : null,
          });
        })}
      </>
    );
  }
}

export { RightsGuard };
