import React from "react";
import { PropTypes } from "prop-types";

import { BoxInformationsInline } from "_components/FicheComponents/BoxInformationsInline";
import { WarningBar } from "_components";
import { App } from "App";

import {
  CiviliteService,
  PersonnelStatutsService,
  PersonnelPositionService,
  PersonnelCoefficientService,
  PersonnelTypeContratService,
  PersonnelFonctionInformatiqueService,
  PersonnelService,
} from "_services";
import { PersonnelMotifRuptureService } from "_services/PersonnelMotifRuptureService";

class Contrat extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ancienneInitiales = this.props.personnel.initiales;

    this.state = {
      callbackForceUpdateCoefficient: null,
    };

    this.setForceUpdateCoefficient = this.setForceUpdateCoefficient.bind(this);
  }

  setForceUpdateCoefficient(callbackFunction) {
    this.setState({
      callbackForceUpdateCoefficient: callbackFunction,
    });
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight("Personnel", "Contrat"); // on obtient les droits généraux
    // const managerRight = App.RightsGuard?.current?.isManager(
    //   this.props.personnel.responsable
    // );
    // if (right != "RW" && managerRight == "RO") right = "RO"; // on bypass si l'oid de la personnes connecté correspond à l'oid de la fiche personnel
    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "Civilité",
          accessor: "civilite",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: CiviliteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 4,
        },
        {
          label: "Nom",
          accessor: "nom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Prénom",
          accessor: "prenom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Initiales",
          accessor: "initiales",
          type: "text",
          invalidText: "Les initiales existent déjà",
          handleBlur: () => {
            // Permet de vérifier si les initiales existent déjà en vérifiant que ce ne sont pas celles déjà utilisées
            PersonnelService.getAllInitiales().then((res) => {
              let inputInitiales = document.getElementsByName("initiales");
              if (
                res.data
                  .filter((val) => val !== this.ancienneInitiales)
                  .find(
                    (element) =>
                      element === this.props.personnel.initiales.toUpperCase()
                  ) === undefined
              ) {
                inputInitiales[0].classList.remove("is-invalid");
              } else {
                inputInitiales[0].classList.add("is-invalid");
              }
            });
          },
          colSize: 4,
        },
        {
          label: "Responsable (initiales)",
          accessor: "responsable",
          type: "selectSearch",
          service: PersonnelService.getAllInitiales,
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
      ];
      const informationsContrat = [
        {
          label: "Date de naissance",
          accessor: "date_Naissance",
          type: "date",
          colSize: 6,
        },
        {
          label: "Lieu de naissance",
          accessor: "lieu_Naissance",
          type: "text",
          colSize: 6,
        },
        {
          label: "Nationalité",
          accessor: "nationalite",
          type: "text",
          colSize: 6,
        },
        {
          label: "Fin du titre de séjour",
          accessor: "fin_Titre_Sejour",
          type: "date",
          colSize: 6,
        },
        {
          label: "Date d'embauche",
          accessor: "date_Embauche",
          type: "date",
          colSize: 4,
        },
        {
          label: "Date de fin de période d'essai",
          accessor: "date_Fin_Periode_Essai",
          type: "date",
          colSize: 4,
        },
        {
          label: "Date de Fin de contrat",
          accessor: "date_Fin_De_Contrat",
          type: "date",
          colSize: 4,
        },
        {
          label: "Motif de rupture",
          accessor: "motif_Rupture_Contrat",
          type: "selectSearch",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: PersonnelMotifRuptureService.getAll,
          colSize: 12,
        },
        {
          label: "Type de contrat",
          accessor: "type_Contrat",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: PersonnelTypeContratService.getAll,
          colSize: 6,
        },
        {
          label: "Statut",
          accessor: "statut",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: PersonnelStatutsService.getAll,
          colSize: 6,
        },
        {
          label: "Position",
          accessor: "position",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: this.props.personnel.statut
            ? () => {
                return PersonnelPositionService.getAll(
                  this.props.personnel.statut.id
                );
              }
            : null,
          afterUpdateFunction: () => {
            this.props.handleChange(
              "coefficient",
              null,
              this.state.callbackForceUpdateCoefficient
            );
          },
          colSize: 6,
        },
        {
          label: "Coefficient",
          accessor: "coefficient",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: PersonnelCoefficientService.getAll,
          setForceUpdateFunction: this.setForceUpdateCoefficient,
          colSize: 6,
        },
        {
          label: "Avance sur frais",
          accessor: "avance_Sur_Frais",
          type: "decimal",
          functionAppliedToValue: (value) => value ?? 0 + "€",
          handleBlur: this.props.handleChange,
          colSize: 6,
        },
      ];
      const mutuelleEtPrevoyance = [
        {
          label: "Numéro de sécurité sociale",
          accessor: "numero_Securite_Sociale",
          type: "text",
          colSize: 12,
        },
        {
          label: "Affiliation mutuelle",
          accessor: "affiliation_Mutuelle",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 6,
        },
        {
          label: "Dispense jusqu'au",
          accessor: "dispense_Mutuelle",
          type: "date",
          colSize: 6,
        },
        {
          label: "Affiliation prévoyance",
          accessor: "affiliation_Prevoyance",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 6,
        },
      ];
      const informationsGestionGenerale = [
        {
          label: "Numéro matricule",
          accessor: "matricule",
          type: "text",
          colSize: 12,
        },
        {
          label: "OID (Identifiant informatique)",
          accessor: "oid",
          type:
            this.props.personnel.oid != "OidSystemWebsprint" ? "text" : null,
          colSize: 12,
        },
        {
          label: "Taux horaire",
          accessor: "taux_Horaire",
          type: "decimal",
          handleBlur: this.props.handleChange,
          colSize: 6,
        },
        {
          label: "Fonction informatique",
          accessor: "fonction_Informatique",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: PersonnelFonctionInformatiqueService.getAll,
          colSize: 6,
        },
      ];

      const informationsMedical = [
        {
          label: "Taux AT",
          accessor: "taux_AT",
          type: "decimal",
          handleBlur: this.props.handleChange,
          colSize: 12,
        },
        {
          label: "Surveillance",
          accessor: "surveillance",
          type: "text",
          colSize: 12,
        },
        {
          label: "Dernière visite médicale",
          accessor: "derniere_Visite_Medicale",
          type: "date",
          colSize: 12,
        },
        {
          label: "Prochaine visite médicale",
          accessor: "prochaine_Visite_Medicale",
          type: "date",
          colSize: 12,
        },
        {
          label: "Restrictions",
          accessor: "restrictions",
          type: "text",
          colSize: 12,
        },
      ];
      return (
        <>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="col-12 col-lg-12 d-flex">
                <BoxInformationsInline
                  title="général"
                  datas={informationsGeneral}
                  state={this.props.personnel}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  NoEdition={right != "RW"}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Informations sur le contrat"
                datas={informationsContrat}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right != "RW"}
              />
              <BoxInformationsInline
                title="Mutuelle et prévoyance"
                datas={mutuelleEtPrevoyance}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right != "RW"}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Informations gestion générale"
                datas={informationsGestionGenerale}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right != "RW"}
              />
              <BoxInformationsInline
                title="Médical"
                datas={informationsMedical}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
                NoEdition={right != "RW"}
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

Contrat.propTypes = {
  personnel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { Contrat };
