import React from "react";
import { Helmet } from "react-helmet";

import { Box, ButtonIcon, DialogForm } from "_components";

import { Input } from "_components/Input";

import { ToLocaleDateString } from "_utils";

import { LigneTache } from ".";

import {
  PersonnelService,
  StatutsTacheService,
  PrioritesTacheService,
  ClientService,
  ProduitInterneService,
  FamilleAffaireService,
  ActionsTacheService,
  ProcessTacheService,
  TacheService,
  ContactService,
  AffaireService,
  DevisService,
  CommandeService,
  ConsultationService,
  FicheDeSuiviService,
  MaterielService,
  ContratService,
  ChantierService,
} from "_services";
import { FiltrePersonnaliseService } from "_services/FiltrePersonnalise";

import {
  faPlus,
  faMinusSquare,
  faPlusSquare,
  faExternalLinkAlt,
  faSave,
} from "@fortawesome/free-solid-svg-icons";
import { produce } from "immer";
import { App } from "App";

class HomePage extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      page: "Home",
      filtre: {
        nomFiltre: null,
      },
      board: null,
      isAccordionEnCoursOpen: true,
      isAccordionDisponiblesOpen: false,
      isAccordionAssigneesOpen: true,
      isAccordionTermineesOpen: false,
      filters: {
        statut: null,
        process: [],
        action: null,
        produitInterne: [],
        priorite: null,
        personnelAssigne: null,
        dateEcheance: null,
        familleAffaire: null,
        client: null,
        contact: null,
        dateCreation: null,
        dateFin: null,
        orderby: null,
      },
      tache: {},
    };

    this.handleChange = this.handleChange.bind(this);
    this.renderFilters = this.renderFilters.bind(this);
    this.renderTableHead = this.renderTableHead.bind(this);
    this.renderEnCours = this.renderEnCours.bind(this);
    this.renderAvailable = this.renderAvailable.bind(this);
    this.renderAssignees = this.renderAssignees.bind(this);
    this.createToastErreur = this.createToastErreur.bind(this);
    this.setForceUpdateContact = this.setForceUpdateContact.bind(this);
    this.setForceUpdateInputDocument =
      this.setForceUpdateInputDocument.bind(this);

    this.setForceUpdateActionTache = this.setForceUpdateActionTache.bind(this);
    this.setOpenDialogCreationTache =
      this.setOpenDialogCreationTache.bind(this);
    this.renderDialogCreationTache = this.renderDialogCreationTache.bind(this);
    this.setOpenDialogUpdateTache = this.setOpenDialogUpdateTache.bind(this);
    this.handleClickEditionTache = this.handleClickEditionTache.bind(this);
    this.setOpenDialogFiltre = this.setOpenDialogFiltre.bind(this);
    this.handleNewParameter = this.handleNewParameter.bind(this);
    this.PostFiltreServiceProxy = this.PostFiltreServiceProxy.bind(this);
    this.GetFiltreServiceProxy = this.GetFiltreServiceProxy.bind(this);
    this.tranformFilters = this.tranformFilters.bind(this);
    this.GetNewTaskBoard = this.GetNewTaskBoard.bind(this);
    this.generateInputDocument = this.generateInputDocument.bind(this);
    this.GetDocumentProxy = this.GetDocumentProxy.bind(this);
  }

  setForceUpdateContact(callbackFunction) {
    this.setState({ callbackForceUpdateContact: callbackFunction });
  }

  setForceUpdateInputDocument(callbackFunction) {
    this.setState({ callbackForceUpdateInputDocument: callbackFunction });
  }

  setForceUpdateActionTache(callbackFunction) {
    this.setState({ callbackForceUpdateActionTache: callbackFunction });
  }

  setOpenDialogCreationTache(openDialogFunc) {
    this.setState({ openDialogCreationTacheFunc: openDialogFunc });
  }

  setOpenDialogUpdateTache(openDialogFunc) {
    this.setState({ openDialogUpdateTacheFunc: openDialogFunc });
  }

  handleNewParameter(accessor, value) {
    if (value != null) {
      let newParameter = JSON.parse(value.filtreJSONstringified);
      this.setState(
        {
          filters: {
            ...newParameter,
            process: newParameter.process ?? [],
            produitInterne: newParameter.produitInterne ?? [],
          },
        },
        () => this.GetNewTaskBoard()
      );
    } else {
      this.setState(
        {
          filters: {
            statut: null,
            process: [],
            action: null,
            produitInterne: [],
            priorite: null,
            personnelAssigne: null,
            dateEcheance: null,
            familleAffaire: null,
            client: null,
            contact: null,
            dateCreation: null,
            dateFin: null,
            orderby: null,
          },
        },
        () => this.GetNewTaskBoard()
      );
    }
  }

  GetNewTaskBoard() {
    TacheService.getTaskBoardViaPost(this.tranformFilters())
      .then((res) => {
        this.setState({ board: res.data, loading: false });
      })
      .catch(this.createToastErreur);
    // TacheService.getTaskBoardViaPost(this.state.filters)
    //   .then((res) => {
    //     this.setState({ board: res.data, loading: false });
    //   })
    //   .catch(() => this.createToastErreur());
  }

  PostFiltreServiceProxy() {
    FiltrePersonnaliseService.post({
      ...this.state.filtre,
      page: this.state.page,
      filtreJSONstringified: JSON.stringify(this.state.filters),
    });
  }

  GetFiltreServiceProxy() {
    return FiltrePersonnaliseService.getByPageAndOid(this.state.page);
  }

  GetDocumentProxy(search) {
    if (this.state.tache.typeEntiteReference == "affaires") {
      return AffaireService.getAll({
        size: 25,
        global: search,
        soldee: false,
        nom_Client: this.state.tache?.client?.nom_Client,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "devis") {
      return DevisService.getAll({
        orderBy: "nom_Devis",
        order: "ASC",
        nom_Client: this.state.tache.client?.nom_Client,
        global: search,
        size: 20,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "commandes") {
      return CommandeService.getAll({
        orderBy: "nom_Commande",
        order: "ASC",
        num_Client: this.state.tache.client?.id,
        global: search,
        size: 20,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "consultations") {
      return ConsultationService.getAll({
        orderBy: "nom_Consultation",
        order: "ASC",
        nom_Client: this.state.tache.client?.nom_Client,
        global: search,
        size: 20,
      });
    } else if (this.state.tache.typeEntiteReference == "fichesDeSuivi") {
      return FicheDeSuiviService.getAll({
        orderBy: "reference",
        order: "ASC",
        nom_Client: this.state.tache.client?.nom_Client,
        global: search,
        size: 20,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "personnels") {
      return PersonnelService.getForSS(search);
    } else if (this.state.tache.typeEntiteReference == "materiels") {
      return MaterielService.getAll({
        size: 25,
        global: search,
        en_Sommeil: false,
        nom_utilisateur: this.state.tache?.client?.nom_client,
      });
    } else if (this.state.tache.typeEntiteReference == "contrats") {
      return ContratService.getAll({
        size: 25,
        global: search,
        nom_Client: this.state.tache?.client?.nom_Client,
        actif: true,
      });
    } else if (this.state.tache.typeEntiteReference == "chantiers") {
      return ChantierService.getAllViaPost({
        size: 25,
        global: search,
        actif: true,
      });
    } else return null;
  }

  setOpenDialogFiltre(openDialogFunc) {
    this.setState({ openDialogFiltreFunc: openDialogFunc });
  }

  componentDidMount() {
    TacheService.getTaskBoardViaPost(this.state.filters)
      .then((res) => {
        this.setState({ board: res.data, loading: false });
      })
      .catch(this.createToastErreur);
  }

  createToastErreur() {
    return App.Toaster.current?.createToast({
      body: "La récupération des tâches a échouée",
      header: "Echec",
      type: "failure",
    });
  }

  tranformFilters() {
    let params = {
      statutId: this.state.filters.statut?.map((e) => e.id) ?? null,
      processId: this.state.filters.process?.map((e) => e.id) ?? null,
      actionId: this.state.filters.action?.map((e) => e.id) ?? null,
      produitInterneId:
        this.state.filters.produitInterne?.map((e) => e.id) ?? null,
      prioriteId: this.state.filters.priorite?.map((e) => e.id) ?? null,
      personnelAssigneId: this.state.filters.personnelAssigne?.id ?? null,
      dateEcheance: this.state.filters.dateEcheance ?? null,
      familleAffaireId:
        this.state.filters.familleAffaire?.map((e) => e.id) ?? null,
      clientId: this.state.filters.client?.id ?? null,
      contactId: this.state.filters.contact?.id ?? null,
      dateCreation: this.state.filters.dateCreation ?? null,
      dateFin: this.state.filters.dateFin ?? null,
      orderby: this.state.filters.orderby ?? null,
    };

    return params;
  }

  handleChange(accessor, value) {
    if (accessor.includes(".")) {
      var accessorSplit = accessor.split(".");

      if (accessorSplit[0] === "filtre") {
        // Format filtre.accessor
        var acc = accessorSplit[1];
        this.setState(
          produce((prevState) => {
            prevState.filters[acc] = value;
          }),
          () => this.GetNewTaskBoard()
        );
      }
    }
  }

  handleCreateTache() {
    TacheService.createTache({
      params: this.state.filters,
    }).then((res) => {
      this.setState({ board: res.data, loading: false });
    });
  }

  handleClickEditionTache(board, idTache) {
    var tache = this.state.board[board].find((el) => el.id == idTache);

    this.setState(
      produce((prevState) => {
        prevState.tache = tache;
      })
    );

    this.state.openDialogUpdateTacheFunc?.();
  }

  renderFilters() {
    return (
      <>
        <div>
          <DialogForm
            tooltip="Imprimer contrat"
            classNameButton="btn btn-success"
            dialogTitle="Impression"
            labelValidateButton="Valider"
            setOpenDialog={this.setOpenDialogFiltre}
            onValidate={() => {
              this.PostFiltreServiceProxy();
            }}
            onClose={() => {}}
            body={
              <div id="PopupCommande">
                <div>
                  Veuillez choisir un nom pour ce filtre <br />
                </div>

                <Input
                  label="Nom du filtre"
                  accessor="nomFiltre"
                  type="text"
                  value={this.state.filtre.nomFiltre}
                  handleChange={(acc, value) =>
                    this.setState({
                      filtre: {
                        ...this.state.filtre,
                        nomFiltre: value,
                      },
                    })
                  }
                  required={true}
                />
              </div>
            }
          />
        </div>

        <Box
          header={
            <>
              <div className="col-12 align-self-center py-2 d-flex flex-row justify-content-between">
                <div>Filtres</div>
                <div className=" a d-flex flex-row ">
                  <Input
                    accessor="parametre"
                    type="selectSearch"
                    service={this.GetFiltreServiceProxy}
                    optionFieldToDisplay="nomFiltre"
                    valueFieldToDisplay="nomFiltre"
                    colSize={12}
                    handleChange={this.handleNewParameter}
                  ></Input>

                  <ButtonIcon
                    icon={faSave}
                    onClick={() => {
                      this.state.openDialogFiltreFunc();
                    }}
                    className="btn btn-success text-light ms-1"
                    style={{ width: "40px" }}
                    iconSize="sm"
                    tooltip="Enregistrer le filtre "
                  ></ButtonIcon>
                </div>
              </div>
            </>
          }
          body={
            <>
              <table className="table table-sm">
                <tbody>
                  <tr>
                    <th className="solwayFont">Statut</th>
                    <th className="solwayFont">Date de création</th>
                    <th className="solwayFont">Client associé</th>
                    <th className="solwayFont">Action</th>
                    <th className="solwayFont">Produit Interne</th>
                    <th className="solwayFont">Date d'échéance</th>
                    <th className="solwayFont">Assignée à</th>
                  </tr>
                  <tr>
                    <td>
                      <Input
                        value={this.state.filters.statut}
                        accessor="filtre.statut"
                        type="multipleSelectSearch"
                        service={StatutsTacheService.getAll}
                        valueFieldToDisplay={"designation"}
                        optionFieldToDisplay={"designation"}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.dateCreation}
                        accessor="filtre.dateCreation"
                        type="period"
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.client}
                        accessor="filtre.client"
                        type="selectSearch"
                        service={ClientService.getForSelectSearch}
                        valueFieldToDisplay={"nom_Client"}
                        optionFieldToDisplay={"nom_Client"}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.action}
                        accessor="filtre.action"
                        type="multipleSelectSearch"
                        service={ActionsTacheService.getAll}
                        valueFieldToDisplay={"designation"}
                        optionFieldToDisplay={"designation"}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.produitInterne}
                        accessor="filtre.produitInterne"
                        type="multipleSelect"
                        service={ProduitInterneService.getAll}
                        valueFieldToDisplay={"designation"}
                        optionFieldToDisplay={"designation"}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.dateEcheance}
                        accessor="filtre.dateEcheance"
                        type="period"
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.personnelAssigne}
                        accessor="filtre.personnelAssigne"
                        type="selectSearch"
                        service={PersonnelService.getForSelectSearch}
                        valueFieldToDisplay={["nom", "prenom"]}
                        optionFieldToDisplay={["nom", "prenom"]}
                        handleChange={this.handleChange}
                      />
                    </td>
                  </tr>
                  <tr className="text-uppercase">
                    <th className="solwayFont">Priorité</th>
                    <th className="solwayFont">Famille d'affaire</th>
                    <th className="solwayFont">Process</th>
                    <th className="solwayFont">Date de fin</th>
                  </tr>
                  <tr>
                    <td>
                      <Input
                        value={this.state.filters.priorite}
                        accessor="filtre.priorite"
                        type="multipleSelectSearch"
                        service={PrioritesTacheService.getAll}
                        valueFieldToDisplay={"designation"}
                        optionFieldToDisplay={"designation"}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.familleAffaire}
                        accessor="filtre.familleAffaire"
                        type="multipleSelectSearch"
                        service={FamilleAffaireService.getAll}
                        valueFieldToDisplay={"designation"}
                        optionFieldToDisplay={"designation"}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.process}
                        accessor="filtre.process"
                        type="multipleSelect"
                        service={ProcessTacheService.getAll}
                        optionFieldToDisplay={"designation"}
                        handleChange={this.handleChange}
                      />
                    </td>
                    <td>
                      <Input
                        value={this.state.filters.dateFin}
                        accessor="filtre.dateFin"
                        type="period"
                        handleChange={this.handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          }
          rowClass="px-2"
        />
      </>
    );
  }

  renderTableHead(zeroElement) {
    return (
      <thead
        className={
          "sticky-top" +
          (zeroElement || this.state.board == null ? " theadBorderRadius" : "")
        }
      >
        <tr>
          <th></th>
          <th>Statut</th>
          <th>Date de création</th>
          <th>Client</th>
          <th>Contact</th>
          <th>Action</th>
          <th>Doc.</th>
          <th>Produit Interne</th>
          <th>Date échéance</th>
          <th>Assignée à</th>
        </tr>
      </thead>
    );
  }

  renderEnCours() {
    return (
      <Box
        header={
          <div className="col-12 py-2 d-flex justify-content-center">
            <ButtonIcon
              icon={
                this.state.isAccordionEnCoursOpen ? faMinusSquare : faPlusSquare
              }
              iconSize="lg"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionEnCoursOpen: !prevState.isAccordionEnCoursOpen,
                }))
              }
              className="btn"
              style={{ color: "#fff", padding: "0px" }}
            />
            <span className="align-self-center" style={{ paddingLeft: "20px" }}>
              {this.state.board?.enCours?.length}
            </span>
            <span
              className="align-self-center mx-auto"
              style={{ paddingLeft: "38px" }}
            >
              Tâches en cours
            </span>
            <ButtonIcon
              icon={faPlus}
              iconSize="sm"
              tooltip="Créer une tâche"
              onClick={this.state.openDialogCreationTacheFunc}
              className="btn btn-success"
            />
          </div>
        }
        body={
          <table
            className={
              "table table-sm" +
              (this.state.board?.enCours?.length > 0 ? "" : " table-bordered")
            }
            style={
              !this.state.isAccordionEnCoursOpen ? { display: "none" } : {}
            }
          >
            {this.renderTableHead(this.state.board?.enCours?.length == 0)}
            <tbody>
              {this.state.board?.enCours
                .slice()
                .sort(
                  (a, b) => new Date(a.dateEcheance) - new Date(b.dateEcheance)
                )
                .map((tache, index) => (
                  <LigneTache
                    key={tache.id}
                    tache={tache}
                    index={index}
                    board="enCours"
                    handleClickEditionTache={this.handleClickEditionTache}
                  />
                ))}
            </tbody>
          </table>
        }
        noPaddingBottom={true}
      />
    );
  }

  renderAvailable() {
    return (
      <Box
        header={
          <div className="col-12 py-2 d-flex justify-content-center">
            <ButtonIcon
              icon={
                this.state.isAccordionDisponiblesOpen
                  ? faMinusSquare
                  : faPlusSquare
              }
              iconSize="lg"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionDisponiblesOpen:
                    !prevState.isAccordionDisponiblesOpen,
                }))
              }
              className="btn mr-auto"
              style={{ color: "#fff", padding: "0px" }}
            />
            <span className="align-self-center" style={{ paddingLeft: "20px" }}>
              {this.state.board?.available?.length}
            </span>
            <span className="align-self-center mx-auto">
              Tâches disponibles
            </span>
          </div>
        }
        body={
          <table
            className={
              "table  table-sm" +
              (this.state.board?.available?.length > 0 ? "" : " table-bordered")
            }
            style={
              !this.state.isAccordionDisponiblesOpen ? { display: "none" } : {}
            }
          >
            {this.renderTableHead(this.state.board?.available?.length == 0)}
            <tbody>
              {this.state.board?.available
                .slice()
                .sort(
                  (a, b) => new Date(a.dateEcheance) - new Date(b.dateEcheance)
                )
                .map((tache, index) => (
                  <LigneTache
                    key={tache.id}
                    tache={tache}
                    index={index}
                    board="available"
                    handleClickEditionTache={this.handleClickEditionTache}
                  />
                ))}
            </tbody>
          </table>
        }
        noPaddingBottom={true}
      />
    );
  }

  renderAssignees() {
    return (
      <Box
        header={
          <div className="col-12 py-2 d-flex justify-content-center">
            <ButtonIcon
              icon={
                this.state.isAccordionAssigneesOpen
                  ? faMinusSquare
                  : faPlusSquare
              }
              iconSize="lg"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionAssigneesOpen: !prevState.isAccordionAssigneesOpen,
                }))
              }
              className="btn"
              style={{ color: "#fff", padding: "0px" }}
            />
            <span className="align-self-center" style={{ paddingLeft: "20px" }}>
              {this.state.board?.assignees?.length}
            </span>
            <span className="align-self-center mx-auto">Tâches du service</span>
          </div>
        }
        body={
          <table
            className={
              "table table-sm" +
              (this.state.board?.assignee?.length > 0 ? "" : " table-bordered")
            }
            style={
              !this.state.isAccordionAssigneesOpen ? { display: "none" } : {}
            }
          >
            {this.renderTableHead(this.state.board?.assignees?.length == 0)}
            <tbody>
              {this.state.board?.assignees
                .slice()
                .sort(
                  (a, b) => new Date(a.dateEcheance) - new Date(b.dateEcheance)
                )
                .map((tache, index) => (
                  <LigneTache
                    key={tache.id}
                    tache={tache}
                    index={index}
                    board="assignees"
                    handleClickEditionTache={this.handleClickEditionTache}
                  />
                ))}
            </tbody>
          </table>
        }
        noPaddingBottom={true}
      />
    );
  }

  generateInputDocument() {
    let propsToPut;
    if (this.state.tache.typeEntiteReference == "affaires") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Affaires",
        optionFieldToDisplay: [
          "produitInterne.designation",
          "reference",
          "nomAffaire",
        ],
        valueFieldToDisplay: ["reference", "nomAffaire"],
      };
    } else if (this.state.tache.typeEntiteReference == "devis") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom_Devis: "",
            }
          : null,
        label: "Devis",
        optionFieldToDisplay: ["reference", "nom_Devis"],
        valueFieldToDisplay: ["reference", "nom_Devis"],
      };
    } else if (this.state.tache.typeEntiteReference == "commandes") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom_Commande: "",
            }
          : null,
        label: "Commandes",
        optionFieldToDisplay: ["reference", "nom_Commande"],
        valueFieldToDisplay: ["reference", "nom_Commande"],
      };
    } else if (this.state.tache.typeEntiteReference == "consultations") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom_Consultation: "",
            }
          : null,
        label: "Consultation",
        optionFieldToDisplay: ["reference", "nom_Consultation"],
        valueFieldToDisplay: ["reference", "nom_Consultation"],
      };
    } else if (this.state.tache.typeEntiteReference == "fichesDeSuivi") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Fiche de suivi",
        optionFieldToDisplay: ["reference"],
        valueFieldToDisplay: ["reference"],
      };
    } else if (this.state.tache.typeEntiteReference == "personnels") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nom: "",
              prenom: "",
            }
          : null,
        label: "Personnel",
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["nom", "prenom"],
      };
    } else if (this.state.tache.typeEntiteReference == "materiels") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Materiel",
        optionFieldToDisplay: ["num_Serie", "type_Materiel.designation"],
        valueFieldToDisplay: ["num_Serie", "type_Materiel.designation"],
      };
    } else if (this.state.tache.typeEntiteReference == "contrats") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              nomAffaire: "",
            }
          : null,
        label: "Contrat",
        optionFieldToDisplay: ["numEtude"],
        valueFieldToDisplay: ["numEtude"],
      };
    } else if (this.state.tache.typeEntiteReference == "chantiers") {
      propsToPut = {
        value: this.state.tache.idReference
          ? {
              id: this.state.tache.idReference,
              reference: "",
            }
          : null,
        label: "Chantier",
        optionFieldToDisplay: ["affaire.reference", "reference"],
        valueFieldToDisplay: ["affaire.reference", "reference"],
      };
    } else return null;

    return (
      <Input
        setForceUpdateFunction={this.setForceUpdateInputDocument}
        accessor="idReference"
        type="selectSearch"
        service={this.GetDocumentProxy}
        handleChange={(accessor, value) => {
          this.setState(
            produce((prevState) => {
              prevState.tache.idReference = value?.id;
            })
          );
        }}
        {...propsToPut}
      />
    );
  }

  renderDialogCreationTache() {
    return (
      <DialogForm
        tooltip="Ajouter une tache"
        classNameButton="btn btn-success"
        dialogTitle="Ajouter une tache"
        labelValidateButton="Valider"
        setOpenDialog={this.setOpenDialogCreationTache}
        onValidate={() => {
          TacheService.postTache(this.state.tache).then(() => {
            this.GetNewTaskBoard();
            this.setState({ tache: {} });
          });
        }}
        onClose={() => this.setState({ tache: {} })}
        body={
          <div
            id="PopupCommande"
            className={"row"}
            style={{ minWidth: "1000px" }}
          >
            <div className={"col-lg-6"}>
              <Input
                value={this.state.tache.process}
                label="Process"
                accessor="process"
                type="selectSearch"
                service={ProcessTacheService.getAll}
                showClearButton={false}
                optionFieldToDisplay={["designation"]}
                valueFieldToDisplay={["designation"]}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      if (prevState.tache.process != value) {
                        prevState.tache.action = null;
                      }
                      prevState.tache.process = value;
                    }),
                    this.state.callbackForceUpdateActionTache
                  );
                }}
              />

              <Input
                value={this.state.tache.action}
                label="Action"
                accessor="action"
                type="selectSearch"
                service={() =>
                  ActionsTacheService.getAll({
                    processId: this.state.tache.process?.id,
                  })
                }
                showClearButton={false}
                optionFieldToDisplay={["designation"]}
                valueFieldToDisplay={["designation"]}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.action = value;
                      if (value?.process != null) {
                        prevState.tache.process = value?.process;
                      }
                    })
                  );
                }}
                setForceUpdateFunction={this.setForceUpdateActionTache}
              />

              <Input
                value={this.state.tache.produitInterne}
                type="selectSearch"
                accessor="produitInterne"
                label="Produit Interne"
                service={ProduitInterneService.getAll}
                showClearButton={false}
                optionFieldToDisplay={["designation"]}
                valueFieldToDisplay={["designation"]}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.produitInterne = value;
                    })
                  );
                }}
              />
              <Input
                value={this.state.tache.familleAffaire}
                accessor="familleAffaire"
                type="selectSearch"
                label="Famille d'affaire"
                service={FamilleAffaireService.getAll}
                showClearButton={false}
                optionFieldToDisplay={["designation"]}
                valueFieldToDisplay={["designation"]}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.familleAffaire = value;
                    })
                  );
                }}
              />
              <Input
                accessor="typeEntiteReference"
                value={
                  this.state.tache
                    ? {
                        value: this.state.tache?.typeEntiteReference,
                        id: 0,
                      }
                    : null
                }
                type="selectSearch"
                label="Type de document"
                options={[
                  {
                    value: "affaires",
                    id: 1,
                  },
                  {
                    value: "devis",
                    id: 2,
                  },
                  {
                    value: "commandes",
                    id: 3,
                  },
                  {
                    value: "fichesDeSuivi",
                    id: 4,
                  },
                  {
                    value: "consultations",
                    id: 6,
                  },
                  {
                    value: "materiels",
                    id: 7,
                  },
                  {
                    value: "contrats",
                    id: 8,
                  },
                  {
                    value: "personnels",
                    id: 9,
                  },
                  {
                    value: "chantiers",
                    id: 10,
                  },
                ]}
                valueFieldToDisplay={"value"}
                optionFieldToDisplay={"value"}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.typeEntiteReference = value?.value;
                    }),
                    this.state.callbackForceUpdateInputDocument
                  );
                }}
              />
            </div>
            <div className={"col-lg-6"}>
              <Input
                value={this.state.tache.client}
                accessor="client"
                type="selectSearch"
                label="Client"
                service={ClientService.getForSelectSearch}
                showClearButton={false}
                optionFieldToDisplay={["nom_Client"]}
                valueFieldToDisplay={["nom_Client"]}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.client = value;
                      prevState.tache.contact = null;
                    }),
                    () => {
                      this.state.callbackForceUpdateContact();
                      if (this.state.callbackForceUpdateInputDocument != null)
                        this.state.callbackForceUpdateInputDocument();
                    }
                  );
                }}
              />

              <Input
                value={this.state.tache.contact}
                accessor="contact"
                type="selectSearch"
                label="Contact"
                service={() =>
                  ContactService.getByIdClient(this.state.tache.client?.id)
                }
                showClearButton={false}
                optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.contact = value;
                    })
                  );
                }}
                setForceUpdateFunction={this.setForceUpdateContact}
              />
              <Input
                accessor="priorite"
                value={this.state.tache.priorite}
                type="selectSearch"
                label="Priorité"
                service={PrioritesTacheService.getAll}
                valueFieldToDisplay={"designation"}
                optionFieldToDisplay={"designation"}
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.priorite = value;
                    })
                  );
                }}
              />

              <Input
                accessor="dateEcheance"
                value={this.state.tache.dateEcheance}
                type="date"
                label="Date d'écheance"
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.dateEcheance = value;
                    })
                  );
                }}
              />

              {this.generateInputDocument()}
            </div>

            <div className={"col-lg-12"}>
              <Input
                value={this.state.tache.commentaire}
                accessor="commentaire"
                type="textArea"
                label="Commentaire"
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.commentaire = value;
                    })
                  );
                }}
              />
            </div>
          </div>
        }
      />
    );
  }

  renderDialogUpdateTache() {
    const isAnAutomaticTask = this.state.tache.type != null;
    return (
      <DialogForm
        tooltip="Editer une tache"
        classNameButton="btn btn-success"
        dialogTitle="Editer une tache"
        labelValidateButton="Valider"
        setOpenDialog={this.setOpenDialogUpdateTache}
        onValidate={() => {
          TacheService.putTache(this.state.tache)
            .then(() => {
              this.GetNewTaskBoard();
              this.setState({ tache: {} });
            })
            .catch(() =>
              App.Toaster.current?.createToast({
                body: "La modification de la tâche a échouée. Cette tâche a été modifiée entre temps par un autre utilisateur",
                header: "Echec",
                type: "failure",
              })
            );
        }}
        onClose={() => this.setState({ tache: {} })}
        body={
          <div
            id="PopupCommande"
            className={"row"}
            style={{ minWidth: "1000px" }}
          >
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <Input
                  value={this.state.tache.statut}
                  label="Statut"
                  accessor="statut"
                  type="selectSearch"
                  service={StatutsTacheService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.statut = value;
                      })
                    );
                  }}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.action}
                  label="Action"
                  accessor="action"
                  type="selectSearch"
                  service={() =>
                    ActionsTacheService.getAll({
                      processId: this.state.tache.process?.id,
                    })
                  }
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.action = value;
                        if (value?.process != null) {
                          prevState.tache.process = value?.process;
                        }
                      })
                    );
                  }}
                  setForceUpdateFunction={this.setForceUpdateActionTache}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
              <div className="pt-2">
                <Input
                  accessor="dateEcheance"
                  value={this.state.tache.dateEcheance}
                  type="date"
                  label="Date d'écheance"
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.dateEcheance = value;
                      })
                    );
                  }}
                />
              </div>
              <div className="pt-2">
                <Input
                  accessor="priorite"
                  value={this.state.tache.priorite}
                  type="selectSearch"
                  label="Priorité"
                  service={PrioritesTacheService.getAll}
                  valueFieldToDisplay={"designation"}
                  optionFieldToDisplay={"designation"}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.priorite = value;
                      })
                    );
                  }}
                />
              </div>
            </div>
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <div class="text-uppercase text-muted">Date de création</div>
                <div class="py-2 px-2">
                  {ToLocaleDateString(this.state.tache.dateCreation) ?? "-"}
                </div>
              </div>
              <div className="pt-2">
                <div class="text-uppercase text-muted">Document</div>

                {isAnAutomaticTask ? (
                  <>
                    <div class="py-2 px-2">
                      {this.state.tache.typeEntiteReference +
                        "/" +
                        this.state.tache.idReference}
                      <ButtonIcon
                        icon={faExternalLinkAlt}
                        className="btn btn-secondary btn-sm text-light ms-1 py-0 px-1"
                        onClick={() =>
                          window.open(
                            "/" +
                              this.state.tache.typeEntiteReference +
                              "/" +
                              this.state.tache.idReference,
                            "_blank"
                          )
                        }
                      ></ButtonIcon>
                    </div>
                  </>
                ) : (
                  <Input
                    accessor="typeEntiteReference"
                    value={
                      this.state.tache
                        ? {
                            value: this.state.tache?.typeEntiteReference,
                            id: 0,
                          }
                        : null
                    }
                    type="selectSearch"
                    options={[
                      {
                        value: "affaires",
                        id: 1,
                      },
                      {
                        value: "devis",
                        id: 2,
                      },
                      {
                        value: "commandes",
                        id: 3,
                      },
                      {
                        value: "fichesDeSuivi",
                        id: 4,
                      },
                      {
                        value: "consultations",
                        id: 6,
                      },
                      {
                        value: "materiels",
                        id: 7,
                      },
                      {
                        value: "contrats",
                        id: 8,
                      },
                      {
                        value: "personnels",
                        id: 9,
                      },
                    ]}
                    valueFieldToDisplay={"value"}
                    optionFieldToDisplay={"value"}
                    handleChange={(accessor, value) => {
                      this.setState(
                        produce((prevState) => {
                          prevState.tache.typeEntiteReference = value?.value;
                        }),
                        () => {
                          if (
                            this.state.callbackForceUpdateInputDocument != null
                          )
                            this.state.callbackForceUpdateInputDocument();
                        }
                      );
                    }}
                  />
                )}
              </div>
              {isAnAutomaticTask ? null : (
                <div className="pt-2">{this.generateInputDocument()}</div>
              )}
              <div className="pt-2">
                <div class="text-uppercase text-muted">Retard</div>
                <div class="py-2 px-2">
                  {this.state.tache.joursRetard
                    ? this.state.tache.joursRetard + "j"
                    : "-"}
                </div>
              </div>
              <div className="pt-2">
                <div class="text-uppercase text-muted">Date de fin</div>
                <div class="py-2 px-2">
                  {ToLocaleDateString(this.state.tache.dateFin) ?? "-"}
                </div>
              </div>
            </div>
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <Input
                  value={this.state.tache.client}
                  accessor="client"
                  type="selectSearch"
                  label="Client"
                  service={ClientService.getForSelectSearch}
                  optionFieldToDisplay={["nom_Client"]}
                  valueFieldToDisplay={["nom_Client"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.client = value;
                        prevState.tache.contact = null;
                      }),
                      () => {
                        this.state.callbackForceUpdateContact();
                        if (this.state.callbackForceUpdateInputDocument != null)
                          this.state.callbackForceUpdateInputDocument();
                      }
                    );
                  }}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.produitInterne}
                  type="selectSearch"
                  accessor="produitInterne"
                  label="Produit Interne"
                  service={ProduitInterneService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.produitInterne = value;
                      })
                    );
                  }}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.familleAffaire}
                  accessor="familleAffaire"
                  type="selectSearch"
                  label="Famille d'affaire"
                  service={FamilleAffaireService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.familleAffaire = value;
                      })
                    );
                  }}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
            </div>
            <div className={"col-lg-3"}>
              <div className="pt-2">
                <Input
                  value={this.state.tache.contact}
                  accessor="contact"
                  type="selectSearch"
                  label="Contact"
                  service={() =>
                    ContactService.getByIdClient(this.state.tache.client?.id)
                  }
                  optionFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  valueFieldToDisplay={["nom_Contact", "prenom_Contact"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.contact = value;
                      })
                    );
                  }}
                  setForceUpdateFunction={this.setForceUpdateContact}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.assigne}
                  label="Assigné"
                  accessor="assigne"
                  type="selectSearch"
                  placeholder=""
                  service={PersonnelService.getForSelectSearch}
                  valueFieldToDisplay={["nom", "prenom"]}
                  optionFieldToDisplay={["nom", "prenom"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        prevState.tache.assigne = value;
                      }),
                      this.state.callbackForceUpdateActionTache
                    );
                  }}
                />
              </div>
              <div className="pt-2">
                <Input
                  value={this.state.tache.process}
                  label="Process"
                  accessor="process"
                  type="selectSearch"
                  service={ProcessTacheService.getAll}
                  optionFieldToDisplay={["designation"]}
                  valueFieldToDisplay={["designation"]}
                  handleChange={(accessor, value) => {
                    this.setState(
                      produce((prevState) => {
                        if (prevState.tache.process != value) {
                          prevState.tache.action = null;
                        }
                        prevState.tache.process = value;
                      }),
                      this.state.callbackForceUpdateActionTache
                    );
                  }}
                  disabled={isAnAutomaticTask}
                  showClearButton={!isAnAutomaticTask}
                />
              </div>
            </div>

            <div className="col-lg-12 pt-2">
              <Input
                value={this.state.tache.commentaire}
                accessor="commentaire"
                type="textArea"
                label="Commentaire"
                handleChange={(accessor, value) => {
                  this.setState(
                    produce((prevState) => {
                      prevState.tache.commentaire = value;
                    })
                  );
                }}
              />
            </div>
          </div>
        }
      />
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Home Page</title>
        </Helmet>
        <div className="p-3 m-4 rounded" id="homepage">
          {this.renderDialogCreationTache()}
          {this.renderDialogUpdateTache()}
          {this.renderFilters()}
          {this.renderEnCours()}
          {this.renderAssignees()}
          {this.renderAvailable()}
        </div>
      </>
    );
  }
}

export { HomePage };
