import React from "react";
import { App } from "App";

import { Box } from "_components";

import { ButtonIcon, WarningBar } from "_components";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { ToLocaleDateString } from "_utils";
import { ProcessTacheService, PersonnelService } from "_services";
import { Input } from "_components/Input";

class HistoriqueClient extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { processId: null, personnelId: null };
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight("Client", "Historique");
    if (right == "RW" || right == "RO") {
      return (
        <>
          <div style={{ display: "flex", marginBottom: "15px" }}>
            <div className="d-flex flex-row align-items-center me-2">
              <div
                style={{
                  width: "80px",
                  flexShrink: "0",
                }}
              >
                Process :
              </div>
              <Input
                type="selectSearch"
                service={ProcessTacheService.getAll}
                valueFieldToDisplay={"designation"}
                optionFieldToDisplay={"designation"}
                optionFieldToReturn={"id"}
                handleChange={(acc, val) => this.setState({ processId: val })}
              />
            </div>

            <div
              className="ms-1 d-flex flex-row align-items-center"
              role="group"
            >
              <div
                style={{
                  width: "90px",
                  flexShrink: "0",
                }}
              >
                Personnel :
              </div>

              <Input
                accessor="personnelAssigneId"
                type="selectSearch"
                service={PersonnelService.getForSelectSearch}
                valueFieldToDisplay={["nom", "prenom"]}
                optionFieldToDisplay={["nom", "prenom"]}
                optionFieldToReturn={"id"}
                handleChange={(acc, val) => this.setState({ personnelId: val })}
              />
            </div>
          </div>
          <Box
            noPaddingBottom={true}
            header={
              <div className="row text-align-center">
                <div className="col-6 py-2">Historique Client</div>

                <div className="col-6 text-end py-2">
                  <ButtonIcon
                    smallText=""
                    icon={faPlus}
                    iconSize="sm"
                    onClick={() => {
                      this.props.openDialogCreationHistoFunc();
                    }}
                    className="btn btn-success"
                  ></ButtonIcon>
                </div>
              </div>
            }
            body={
              <div
                className="lock-header "
                style={{
                  padding: "0 0 0 0 ",
                }}
              >
                <table className="tableHistorique table table-striped table-bordered table-sm">
                  <thead
                    className={
                      "sticky-top" +
                      (this.props.client?.historiques?.length == 0
                        ? " theadBorderRadius"
                        : "")
                    }
                  >
                    <tr>
                      <th className="text-nowrap ps-2">Date</th>
                      <th className="text-nowrap ps-2">Process</th>
                      <th className="text-nowrap ps-2">Action</th>
                      <th className="text-nowrap ps-2">Par</th>
                      <th className="text-nowrap ps-2">Document</th>
                      <th className="text-nowrap ps-2">Produit Interne</th>
                      <th className="text-nowrap ps-2">Famille d'affaire</th>
                      <th className="text-nowrap ps-2">Contact</th>
                      <th className="text-nowrap ps-2">Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.client?.historiques
                      ?.slice()

                      ?.filter((e) => {
                        let bool = true;
                        if (this.state.processId) {
                          bool = e.process?.id == this.state.processId;
                        }

                        if (this.state.personnelId) {
                          bool =
                            bool && e.finishedBy?.id == this.state.personnelId;
                        }

                        return bool;
                      })
                      ?.sort(
                        (a, b) =>
                          new Date(b.dateCreation) - new Date(a.dateCreation)
                      )
                      .map((historique) => {
                        return (
                          <tr key={historique.id}>
                            <td className="ps-2">
                              {ToLocaleDateString(historique.dateCreation)}
                            </td>
                            <td className="ps-2">
                              {historique.process?.designation ?? "-"}
                            </td>
                            <td className="ps-2">{historique.action ?? "-"}</td>
                            <td className="ps-2">
                              {historique.finishedBy?.initiales ?? "-"}
                            </td>
                            <td
                              className="ps-2"
                              style={{ minWidth: "120px", maxWidth: "200px" }}
                            >
                              {historique.typeEntiteReference ? (
                                <a
                                  href={
                                    "/" +
                                    historique.typeEntiteReference +
                                    "/" +
                                    historique.idReference
                                  }
                                  target="_blank"
                                >
                                  {historique.typeEntiteReference}
                                  <wbr />
                                  {"/" + historique.idReference}
                                </a>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="ps-2">
                              {historique.produitInterne?.designation ?? "-"}
                            </td>
                            <td className="ps-2">
                              {historique.familleAffaire?.designation ?? "-"}
                            </td>
                            <td className="ps-2">
                              {historique.contact ? (
                                <a
                                  href={"/contacts/" + historique.contact.id}
                                  target="_blank"
                                >
                                  {historique.contact.prenom_Contact +
                                    " " +
                                    historique.contact.nom_Contact}
                                  {/* <ButtonIcon
                                    icon={faExternalLinkAlt}
                                    className="btn btn-secondary btn-sm text-light ms-1 py-0 px-1"
                                    onClick={() =>
                                      window.open(
                                        "/contacts/" + historique.contact.id,
                                        "_blank"
                                      )
                                    }
                                  ></ButtonIcon> */}
                                </a>
                              ) : (
                                "-"
                              )}
                            </td>
                            <td className="ps-2 keepWhiteSpace">
                              {historique.description}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            }
          />
        </>
      );
    } else
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
  }
}

export { HistoriqueClient };
