import {
  faExclamationTriangle,
  faExternalLinkAlt,
  faHourglass,
  faPrint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ButtonIcon, DataFieldColumn } from "_components";
import { ChantierService, PersonnelService } from "_services";

import * as FileSaver from "file-saver";

import moment from "moment";

import React from "react";

const getColorByNow = (year, week, chantier) => {
  let now = moment();
  let start = moment(new Date(year, 1, 1));
  let color = "";
  let weekNow = now.isoWeek();
  if (now.year() > start.year() || chantier.statut.designation == "Terminé") {
    // color 1
    color = "red";
  } else if (now.year() == start.year()) {
    if (weekNow == week) {
      //color 2
      color = "green";
    } else if (weekNow > week) {
      // color 1
      color = "red";
    } else {
      // color 3
      color = "yellow";
    }
  } else {
    // color 3
    color = "yellow";
  }
  return color;
};

const BlocChantierSynthese = (props) => {
  let chantier = props.chantier;

  let handleAddHeures = () => {
    sessionStorage.setItem("FichePersonnelOnglet", "Heures");

    PersonnelService.getByOid().then((res) => {
      props.history.push({
        pathname: "/personnels/" + res.data.id,
        state: { chantier: props.chantier },
      });
    });
  };

  let boxHeader = (
    <>
      <div className=" align-self-center py-2 elementHeaderChantierSynthese1">
        <div className="sousElementHeaderChantierSynthese1">
          <div
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "10px",
              backgroundColor: getColorByNow(
                chantier.anneeChantier,
                chantier.semaineChantier,
                chantier
              ),
              marginRight: "5px",
              flexShrink: "0",
            }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>{chantier.reference}/</div>
            <div>{chantier.affaire.nomAffaire}</div>
            <div>{chantier.affaire.produitInterne.designation}</div>
          </div>
        </div>
      </div>
      <div className=" align-self-center text-center py-2 text-warning elementHeaderChantierSynthese2">
        {chantier.hasHeureNotValidated ? (
          <>
            <FontAwesomeIcon icon={faExclamationTriangle} className="mx-2" />
            Heures non validées
            <br />
          </>
        ) : null}
      </div>
      <div className=" text-end py-2 elementHeaderChantierSynthese3">
        {chantier.hasHeureNotValidated ? (
          <ButtonIcon
            icon={faHourglass}
            className="btn btn-secondary text-light ms-1"
            style={{ width: "42px" }}
            onClick={() => {
              handleAddHeures();
            }}
          />
        ) : null}
        {chantier.affaire?.produitInterne?.designation == "ENDO" ? (
          <ButtonIcon
            icon={faPrint}
            className="btn btn-secondary text-light ms-1"
            style={{ width: "42px" }}
            onClick={() => {
              ChantierService.print(chantier.affaire.id).then((res) => {
                var blob = new Blob([res?.data], {
                  type: "application/pdf;base64",
                });
                FileSaver.saveAs(blob, chantier.reference + ".pdf");
              });
            }}
          />
        ) : null}
        <ButtonIcon
          icon={faExternalLinkAlt}
          className="btn btn-secondary text-light ms-1"
          style={{ width: "42px" }}
          onClick={() => {
            props.history.push("/chantiers/" + props.chantier.id);
          }}
        />
      </div>
    </>
  );

  let box = (
    <Box
      header={boxHeader}
      rowClass="overflow-auto"
      boxClass="col-11"
      body={
        <div>
          <div className="row">
            <div className="col">
              <div>
                <DataFieldColumn
                  label="Année"
                  type="text"
                  value={chantier.anneeChantier}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Semaine"
                  type="text"
                  value={chantier.semaineChantier}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Date début"
                  type="text"
                  value={chantier.dateDebutChantier}
                  functionAppliedToValue={(value) =>
                    value ? new Date(value).toLocaleDateString("fr-FR") : "-"
                  }
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Heure arrivée bureau"
                  type="text"
                  value={chantier.heureArriveeBureau}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Heure RDV"
                  type="text"
                  value={chantier.heureRDV}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Lieu RDV"
                  type="text"
                  value={chantier.lieuRDV}
                />
              </div>

              <div>
                <DataFieldColumn
                  label="Statut"
                  type="text"
                  value={chantier.statut?.designation ?? "-"}
                />
              </div>
              {chantier.statut?.designation == "Annulé" ? (
                <div>
                  <DataFieldColumn
                    label="Motif annulation"
                    type="text"
                    value={chantier.motifAnnulation?.designation ?? "-"}
                  />
                </div>
              ) : null}
            </div>

            <div className="col">
              <div>
                <DataFieldColumn
                  label="Contact"
                  type="text"
                  value={chantier.contact}
                  functionAppliedToValue={(value) => {
                    return value ? (
                      <a
                        href={"/contacts/" + value.id}
                        target="_blank"
                        data-text={
                          value.nom_Contact + " " + value.prenom_Contact
                        }
                        className="fixWidthHover"
                      >
                        {value.nom_Contact + " " + value.prenom_Contact}
                      </a>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Téléphone"
                  type="text"
                  value={chantier.contact}
                  functionAppliedToValue={(value) => {
                    return value ? value.portable : "-";
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="PP"
                  type="text"
                  value={chantier.planPrevention}
                  functionAppliedToValue={(value) => {
                    return value ? "Oui" : "Non";
                  }}
                />
              </div>

              <div>
                <DataFieldColumn
                  label="Labo Granulo"
                  type="text"
                  value={chantier.labo}
                  functionAppliedToValue={(value) => {
                    return value ? "Oui" : "Non";
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Lieu chantier"
                  type="text"
                  value={chantier.lieuChantier}
                  functionAppliedToValue={(value) => {
                    return value ? (
                      <div>
                        <div>{value.voie}</div>
                        <div>{value.complement}</div>
                        <div>{value.ville + ", " + value.cp}</div>
                        <div>{value.pays?.nom_fr}</div>
                      </div>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Lien GPS"
                  type="text"
                  value={chantier.lienGPS}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <DataFieldColumn
                  label="Nuits prévues"
                  type="text"
                  value={chantier.nbNuitsPrev}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Jours prévus"
                  type="text"
                  value={chantier.nbJoursPrev}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Nb sondages prévus"
                  type="text"
                  value={chantier.nbSondagesPrev}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Nb sondages réalisés"
                  type="text"
                  value={chantier.nbSondagesRealises}
                />
              </div>
            </div>
            <div className="col">
              <div>
                <DataFieldColumn
                  label="Techniciens"
                  type="text"
                  value={chantier.techniciens}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.initiales}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Véhicules"
                  type="text"
                  value={chantier.vehicules}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.designation}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Préparateurs"
                  type="text"
                  value={chantier.preparateurs}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.initiales}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
              <div>
                <DataFieldColumn
                  label="Matériels"
                  type="text"
                  value={chantier.materielsChantier}
                  functionAppliedToValue={(value) => {
                    return value && value.length > 0 ? (
                      <>
                        {value?.map((e) => (
                          <div>{e.designation}</div>
                        ))}
                      </>
                    ) : (
                      "-"
                    );
                  }}
                />
              </div>
            </div>
            <div className="col">
              <DataFieldColumn
                label="Zone réservation"
                type="text"
                value={chantier.zoneReservation}
              />
              <DataFieldColumn
                label="Période"
                type="text"
                value={chantier}
                functionAppliedToValue={(value) => {
                  if (value) {
                    let text =
                      (value.dateReservationDebut
                        ? new Date(
                            value.dateReservationDebut
                          ).toLocaleDateString("fr-FR")
                        : "") +
                      " | " +
                      (value.dateReservationFin
                        ? new Date(value.dateReservationFin).toLocaleDateString(
                            "fr-FR"
                          )
                        : "");
                    return text == " | " ? "-" : text;
                  }
                  return "-";
                }}
              />
              <DataFieldColumn
                label="Proposition hôtel"
                type="text"
                value={chantier.propositionHotel}
              />
              <DataFieldColumn
                label="Localisation hôtel"
                type="text"
                value={chantier.localisationHotel}
              />
              <DataFieldColumn
                label="Nom hôtel"
                type="text"
                value={chantier.nomHotel}
              />
              <DataFieldColumn label="Prix" type="text" value={chantier.prix} />

              <DataFieldColumn
                label="CB"
                type="text"
                value={chantier.cb}
                functionAppliedToValue={(value) => (value ? value.name : "-")}
              />
            </div>
            <div className="col">
              <DataFieldColumn
                label="Montant remboursé 1"
                type="text"
                value={chantier.montantRembourse1}
              />
              <DataFieldColumn
                label="Montant remboursé 2"
                type="text"
                value={chantier.montantRembourse2}
              />
              <DataFieldColumn
                label="Supplément 1"
                type="text"
                value={chantier.supplement1}
              />
              <DataFieldColumn
                label="Supplément 2"
                type="text"
                value={chantier.supplement2}
              />

              <DataFieldColumn
                label="Petit déjeuner"
                type="text"
                value={chantier.petitDej}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn
                label="Soirée étape"
                type="text"
                value={chantier?.soireeEtape}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
              <DataFieldColumn
                label="Annulation"
                type="text"
                value={chantier.annulation}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />

              <DataFieldColumn
                label="Parking"
                type="text"
                value={chantier.parking}
                functionAppliedToValue={(value) => (value ? "Oui" : "Non")}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <DataFieldColumn
                label="Commentaire"
                type="text"
                value={chantier.commentaire}
              />
            </div>
            <div className="col-6">
              <DataFieldColumn
                label="Commentaire hôtel"
                type="text"
                value={chantier.commentaireHotel}
              />
            </div>
          </div>
        </div>
      }
    ></Box>
  );
  return <div>{box}</div>;
};

export { BlocChantierSynthese };
