import React from "react";
import { PropTypes } from "prop-types";

import { BoxInformationsInline, Box, ButtonIcon } from "_components";
import {
  PersonnelDiplomeService,
  CiviliteService,
  PersonnelService,
} from "_services";
import { WarningBar } from "_components";

import {
  faPlus,
  faCheck,
  faTimes,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { LigneFormationPersonnel } from "./LigneFormationPersonnel";
import { LigneHabilitationPersonnel } from "./LigneHabilitationPersonnel";

import { App } from "App";

class FormationsCompetences extends React.PureComponent {
  constructor(props) {
    super(props);
    this.ancienneInitiales = this.props.personnel.initiales;

    this.handleClickEditionFormationsPersonnel =
      this.handleClickEditionFormationsPersonnel.bind(this);
    this.handleClickUndoFormationsPersonnel =
      this.handleClickUndoFormationsPersonnel.bind(this);
    this.handleClickValidateFormationsPersonnel =
      this.handleClickValidateFormationsPersonnel.bind(this);
    this.handleClickEditionHabilitationsPersonnel =
      this.handleClickEditionHabilitationsPersonnel.bind(this);
    this.handleClickUndoHabilitationsPersonnel =
      this.handleClickUndoHabilitationsPersonnel.bind(this);
    this.handleClickValidateHabilitationsPersonnel =
      this.handleClickValidateHabilitationsPersonnel.bind(this);
  }

  handleClickEditionFormationsPersonnel() {
    this.setState({
      savedPersonnel: { ...this.props.personnel },
    });
    this.props.handleEditingFormationsPersonnel(true);
  }

  handleClickUndoFormationsPersonnel() {
    this.props.handleChange("personnel", this.state.savedPersonnel);
    this.props.handleEditingFormationsPersonnel(false);
  }

  handleClickValidateFormationsPersonnel() {
    this.props.handleUpdate();
    this.props.handleEditingFormationsPersonnel(false);
  }

  handleClickEditionHabilitationsPersonnel() {
    this.setState({
      savedPersonnel: { ...this.props.personnel },
    });
    this.props.handleEditingHabilitationsPersonnel(true);
  }

  handleClickUndoHabilitationsPersonnel() {
    this.props.handleChange("personnel", this.state.savedPersonnel);
    this.props.handleEditingHabilitationsPersonnel(false);
  }

  handleClickValidateHabilitationsPersonnel() {
    this.props.handleUpdate();
    this.props.handleEditingHabilitationsPersonnel(false);
  }

  render() {
    let right = App.RightsGuard?.current?.hasRight(
      "Personnel",
      "Formation/Competences"
    ); // on obtient les droits généraux

    const managerRight = App.RightsGuard?.current?.isManager(
      this.props.personnel.responsable
    );
    if (right != "RW" && managerRight == "RO") right = "RO";

    if (right == "RW" || right == "RO") {
      const informationsGeneral = [
        {
          label: "Civilité",
          accessor: "civilite",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: CiviliteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 4,
        },
        {
          label: "Nom",
          accessor: "nom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Prénom",
          accessor: "prenom",
          type: "text",
          colSize: 4,
        },
        {
          label: "Initiales",
          accessor: "initiales",
          type: "text",
          invalidText: "Les initiales existent déjà",
          handleBlur: () => {
            // Permet de vérifier si les initiales existent déjà en vérifiant que ce ne sont pas celles déjà utilisées
            PersonnelService.getAllInitiales().then((res) => {
              let inputInitiales = document.getElementsByName("initiales");
              if (
                res.data
                  .filter((val) => val !== this.ancienneInitiales)
                  .find(
                    (element) =>
                      element === this.props.personnel.initiales.toUpperCase()
                  ) === undefined
              ) {
                inputInitiales[0].classList.remove("is-invalid");
              } else {
                inputInitiales[0].classList.add("is-invalid");
              }
            });
          },
          colSize: 4,
        },
        {
          label: "Responsable (initiales)",
          accessor: "responsable",
          type: "selectSearch",
          service: PersonnelService.getAllInitiales,
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
      ];

      const formationsGenerales = [
        {
          label: "Diplôme",
          accessor: "diplome",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          service: PersonnelDiplomeService.getAll,
          colSize: 12,
        },
        {
          label: "Permis B",
          accessor: "permisB",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 3,
        },
        {
          label: "Permis BE",
          accessor: "permisBE",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 3,
        },
        {
          label: "Permis C",
          accessor: "permisC",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 3,
        },
        {
          label: "Permis CE",
          accessor: "permisCE",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 3,
        },
        {
          label: "Date expiration permis B",
          accessor: "expiration_Permis",
          type: "date",
          colSize: 4,
        },
        {
          label: "SST",
          accessor: "sst",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 12,
        },
      ];
      const suiviIndividuel = [
        {
          label: "Date entretien individuel",
          accessor: "date_Entretien_Indiv",
          type: "date",
          colSize: 12,
        },
        {
          label: "Date dernier bilan à 6 ans",
          accessor: "dateDernierBilanA6Ans",
          type: "date",
          colSize: 12,
        },
      ];
      return (
        <>
          <div className="row">
            <div className="col-12 d-flex">
              <div className="col-12 col-lg-12 d-flex">
                <BoxInformationsInline
                  title="général"
                  datas={informationsGeneral}
                  state={this.props.personnel}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  handleUpdate={this.props.handleUpdate}
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Formations générales"
                datas={formationsGenerales}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
              />
              <Box
                noPaddingBottom={true}
                header={
                  <>
                    <div className="col-6 align-self-center py-2">
                      <div>Habilitations/Compétences</div>
                    </div>
                    <div className="col-6 text-end py-2">
                      <ButtonIcon
                        icon={faPlus}
                        iconSize="sm"
                        onClick={() =>
                          this.props.addLineHabilitationPersonnel?.()
                        }
                        className="btn btn-success addHabilitation"
                        style={
                          !this.props.editingHabilitationsPersonnel
                            ? { display: "none" }
                            : {}
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success text-light ms-1"
                        style={
                          !this.props.editingHabilitationsPersonnel
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                        onClick={this.handleClickValidateHabilitationsPersonnel}
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger text-light ms-1"
                        onClick={this.handleClickUndoHabilitationsPersonnel}
                        style={
                          !this.props.editingHabilitationsPersonnel
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faPen}
                        className="btn btn-secondary text-light ms-1"
                        onClick={this.handleClickEditionHabilitationsPersonnel}
                        style={
                          this.props.editing
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                      ></ButtonIcon>
                    </div>
                  </>
                }
                body={
                  <>
                    <table
                      className={
                        "text-uppercase table table-sm " +
                        (this.props.personnel.habilitations?.length > 0
                          ? ""
                          : "table-bordered")
                      }
                    >
                      <thead
                        className={
                          "sticky-top" +
                          (this.props.personnel.habilitations?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Date d'obtention</th>
                          <th>Intitulé compétence</th>
                          <th>Date d'échéance</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.personnel.habilitations
                          ?.slice()
                          .map((habilitation) => (
                            <LigneHabilitationPersonnel
                              key={habilitation.id}
                              habilitation={habilitation}
                              handleChange={this.props.handleChange}
                              editing={this.props.editingHabilitationsPersonnel}
                              deleteLineHabilitationPersonnel={
                                this.props.deleteLineHabilitationPersonnel
                              }
                            />
                          ))}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Suivi individuel"
                datas={suiviIndividuel}
                state={this.props.personnel}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                handleUpdate={this.props.handleUpdate}
              />
              <Box
                noPaddingBottom={true}
                header={
                  <>
                    <div className="col-6 align-self-center py-2">
                      <div>Formations</div>
                    </div>
                    <div className="col-6 text-end py-2">
                      <ButtonIcon
                        icon={faPlus}
                        iconSize="sm"
                        onClick={() => this.props.addLineFormationPersonnel?.()}
                        className="btn btn-success addFormation"
                        style={
                          !this.props.editingFormationsPersonnel
                            ? { display: "none" }
                            : {}
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faCheck}
                        className="btn btn-success text-light ms-1"
                        style={
                          !this.props.editingFormationsPersonnel
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                        onClick={this.handleClickValidateFormationsPersonnel}
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faTimes}
                        className="btn btn-danger text-light ms-1"
                        onClick={this.handleClickUndoFormationsPersonnel}
                        style={
                          !this.props.editingFormationsPersonnel
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                      ></ButtonIcon>
                      <ButtonIcon
                        icon={faPen}
                        className="btn btn-secondary text-light ms-1"
                        onClick={this.handleClickEditionFormationsPersonnel}
                        style={
                          this.props.editing
                            ? { display: "none" }
                            : { width: "42px" }
                        }
                      ></ButtonIcon>
                    </div>
                  </>
                }
                body={
                  <>
                    <table
                      className={
                        "text-uppercase table table-sm " +
                        (this.props.personnel.formations?.length > 0
                          ? ""
                          : "table-bordered")
                      }
                    >
                      <thead
                        className={
                          "sticky-top" +
                          (this.props.personnel.formations?.length == 0
                            ? " theadBorderRadius"
                            : "")
                        }
                      >
                        <tr>
                          <th>Date formation</th>
                          <th>Intitulé formation</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.personnel.formations
                          ?.slice()
                          .map((formation) => (
                            <LigneFormationPersonnel
                              key={formation.id}
                              formation={formation}
                              handleChange={this.props.handleChange}
                              editing={this.props.editingFormationsPersonnel}
                              deleteLineFormationPersonnel={
                                this.props.deleteLineFormationPersonnel
                              }
                            />
                          ))}
                      </tbody>
                    </table>
                  </>
                }
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

FormationsCompetences.propTypes = {
  personnel: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { FormationsCompetences };
