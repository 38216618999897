import React from "react";
import { PropTypes } from "prop-types";
import { App } from "App";

import "./InformationsFicheDeSuivi.css";
import { BoxInformationsInline, WarningBar } from "_components";

import { IfNullToText, ToLocaleDateString } from "_utils";
import {
  FournisseurService,
  UtilisationService,
  TypePanneService,
  FamilleArticleService,
  EtatAvancementService,
  PersonnelService,
  MaterielService,
  ClientService,
  ContactService,
  ProduitInterneService,
  FicheDeSuiviTypeService,
  FicheDeSuiviProvenanceService,
} from "_services";

class InformationsFicheDeSuivi extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      callBackforceUpdateTypePanne: null,
      editingInfMat: false,
    };

    this.setForceUpdateTypePanne = this.setForceUpdateTypePanne.bind(this);
    this.handleEditingFicheMat = this.handleEditingFicheMat.bind(this);
    this.handleUndo = this.handleUndo.bind(this);
    this.setForceUpdateContactClient =
      this.setForceUpdateContactClient.bind(this);
    this.getAllMaterielProxy = this.getAllMaterielProxy.bind(this);
    this.getAllMaterielLocationProxy =
      this.getAllMaterielLocationProxy.bind(this);
  }

  setForceUpdateContactClient(callbackFunction) {
    this.setState({
      callbackForceUpdateContactClient: callbackFunction,
    });
  }

  setForceUpdateTypePanne(callbackFunction) {
    this.setState({ callBackforceUpdateTypePanne: callbackFunction });
  }

  handleEditingFicheMat(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editingInfMat: editing,
    }));

    if (editing) {
      this.setState({
        savedfds: { ...this.props.ficheDeSuivi },
      });
    }

    this.props.handleEditing(editing);
  }

  handleUndo() {
    this.props.handleChange("ficheDeSuivi", this.state.savedfds);
  }

  getAllMaterielProxy(search) {
    return MaterielService.getAll({
      size: 25,
      global: search,
      en_Sommeil: false,
      nom_utilisateur: this.props.ficheDeSuivi?.utilisateur?.nom_client,
    });
  }

  getAllMaterielLocationProxy(search) {
    return MaterielService.getAll({
      size: 25,
      global: search,
      en_Sommeil: false,
      location: true,
    });
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight(
      "Fiche de suivi",
      "General"
    );

    if (right == "RW" || right == "RO") {
      const numeroEtatAvancement =
        this.props.ficheDeSuivi.etat_Avancement?.numero.toString();

      const prep = [
        {
          value: this.props.ficheDeSuivi.source,
          label: "Source",
          accessor: "source",
          type: "selectSearch",
          service: FicheDeSuiviProvenanceService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          required: true,
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
        },
        {
          value: this.props.ficheDeSuivi.sujet,
          label: "Sujet",
          accessor: "sujet",
          type: "selectSearch",
          service: FicheDeSuiviTypeService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          required: true,
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
        },
        {
          value: this.props.ficheDeSuivi.produitInterne,
          label: "Produit Interne",
          accessor: "produitInterne",
          type: "selectSearch",
          service: ProduitInterneService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          required: true,
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
        },
        {
          value: this.props.ficheDeSuivi.isAssistance,
          label: "Assistance",
          accessor: "isAssistance",
          type: "checkbox",
          required: true,
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
      ];

      const InformationsFicheDeSuivi = [
        {
          label: "N°",
          accessor: "reference",
          colSize: 4,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          colSize: 4,
        },
        {
          label: "Statut",
          accessor: "statut",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          colSize: 4,
        },
        {
          label: "Date de création",
          accessor: "date_Create",
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? ToLocaleDateString(value) : "-";
          },
        },

        {
          label: "Date de cloture",
          accessor: "cloture",
          type: "date",
          colSize: 4,
          disabled: true,
        },
        ...(!this.props.ficheDeSuivi?.isAssistance
          ? [
              {
                label: "Date de reception",
                accessor: "date_Reception",
                type: "date",
                colSize: 4,
                required: ["2", "3", "4"].indexOf(numeroEtatAvancement) !== -1,
              },
            ]
          : []),
        ...(!this.props.ficheDeSuivi?.isAssistance
          ? [
              {
                label: "Emplacement",
                accessor: "emplacement",
                type: "selectSearch",
                functionAppliedToValue: (value) => {
                  return value ? value.designation : null;
                },
                service: FournisseurService.getAll,
                optionFieldToDisplay: "designation",
                valueFieldToDisplay: "designation",
                invalidText: "Vous devez sélectionner un emplacement.",
                required: ["3", "4"].indexOf(numeroEtatAvancement) !== -1,
                colSize: 4,
              },
            ]
          : []),
        ...(!this.props.ficheDeSuivi?.isAssistance
          ? [
              {
                label: "Passage chez FUJI",
                accessor: "passageChezFuji",
                colSize: 4,
              },
            ]
          : []),

        {
          label: "Etat d'avancement",
          accessor: "etat_Avancement",
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          type: "selectSearch",
          service: EtatAvancementService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          colSize: 4,
          required: true,
          customFilter: (options) => {
            console.log(options);
            return this.props.ficheDeSuivi?.isAssistance
              ? () =>
                  options.filter(
                    (e) => e.name.includes("0") || e.name.includes("1")
                  )
              : () => options.filter((e) => !e.name.includes("0"));
          },
        },
        {
          label: "Gerant",
          accessor: "gerant",
          functionAppliedToValue: (value) => {
            return value ? "".concat(value.nom, " ", value.prenom) : null;
          },
          service: PersonnelService.getForSelectSearch,
          optionFieldToDisplay: ["nom", "prenom"],
          valueFieldToDisplay: ["nom", "prenom"],
          type: "selectSearch",
          colSize: 4,
        },
      ];
      const informationsClient = [
        {
          label: "Client propriétaire",
          accessor: "materiel.proprietaire",
          functionAppliedToValue: (value) => (
            <div>
              {value ? (
                <a
                  href={"/clients/" + value.id}
                  target="_blank"
                  data-text={value.nom_Client}
                  className="fixWidthHover"
                >
                  {value.nom_Client}
                </a>
              ) : (
                "-"
              )}
            </div>
          ),
          colSize: 4,
        },
        {
          label: "Client utilisateur",
          accessor: "utilisateur",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          afterUpdateFunction: () => {
            this.props.handleChange(
              "contact",
              null,
              this.setForceUpdateContactClient
            );
          },
          functionAppliedToValue: (value) => (
            <>
              <div className="d-flex justify-content-start align-items-center">
                {value && value.nom_Client ? (
                  <a
                    href={"/clients/" + value.id}
                    target="_blank"
                    data-text={value.nom_Client}
                    className="fixWidthHover"
                  >
                    {value.nom_Client}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            </>
          ),
          colSize: 4,
        },
        {
          label: "Contact",
          accessor: "contactUtilisateur",
          type: "selectSearch",
          service: () =>
            ContactService.getByIdClient(
              this.props.ficheDeSuivi.utilisateur?.id
            ),
          optionFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          valueFieldToDisplay: ["nom_Contact", "prenom_Contact"],
          invalidText: "Vous devez sélectionner un contact.",
          setForceUpdateFunction: this.setForceUpdateContactClient,
          colSize: 4,
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/contacts/" + value.id}
                    target="_blank"
                    className="fixWidthHover"
                    data-text={
                      IfNullToText(value.nom_Contact, "") +
                      " " +
                      IfNullToText(value.prenom_Contact, "")
                    }
                  >
                    {IfNullToText(value.nom_Contact, "") +
                      " " +
                      IfNullToText(value.prenom_Contact, "")}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
        },
        {
          label: "Telephone contact",
          accessor: "contactUtilisateur",
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? value.standard : null;
          },
        },
        {
          label: "Portable contact",
          accessor: "contactUtilisateur",
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? value.portable : null;
          },
        },
        {
          label: "Email contact",
          accessor: "contactUtilisateur",
          colSize: 4,
          functionAppliedToValue: (value) => {
            return value ? value.email : null;
          },
        },
      ];

      let infoMat = [
        !this.props.ficheDeSuivi.materiel?.type_Materiel // si pas de materiel/materiel avec un type materiel on laisse le choix
          ? {
              label: "Type Materiel",
              accessor: this.props.ficheDeSuivi.materiel
                ? "materiel.type_Materiel"
                : "type_Materiel",
              functionAppliedToValue: (value) => {
                return value ? value.designation : null;
              },
              required: ["2", "3", "4"].indexOf(numeroEtatAvancement) !== -1,
              type: "selectSearch",
              service: FamilleArticleService.getAll,
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              afterUpdateFunction: () => {
                this.props.handleChange(
                  "type_Panne",
                  null,
                  this.state.callBackforceUpdateTypePanne
                );
              },
              colSize: 4,
            } // sinon
          : {
              label: "Type Materiel",
              accessor: "materiel",
              functionAppliedToValue: (value) => {
                return value ? value.type_Materiel.designation : null;
              },
              colSize: 4,
            },
        {
          label: "Numéro de série",
          accessor: "materiel",
          type: "selectSearch",
          required:
            ["2", "3", "4"].indexOf(numeroEtatAvancement) !== -1 &&
            this.props.ficheDeSuivi.type_Materiel?.designation !=
              "Colonne de battage",
          colSize: 4,
          service: this.getAllMaterielProxy,
          optionFieldToDisplay: ["type_Materiel.designation", "num_Serie"],
          valueFieldToDisplay: ["type_Materiel.designation", "num_Serie"],
          functionAppliedToValue: (value) => {
            return (
              <div className="d-flex justify-content-start align-items-center">
                {value ? (
                  <a
                    href={"/materiels/" + value.id}
                    target="_blank"
                    className="fixWidthHover"
                    data-text={IfNullToText(value.num_Serie, "")}
                  >
                    {IfNullToText(value.num_Serie, "")}
                  </a>
                ) : (
                  "-"
                )}
              </div>
            );
          },
          afterUpdateFunction: () => {
            this.props.handleChange(
              "type_Materiel",
              null,
              this.state.callBackforceUpdateTypePanne
            );
          },
        },
        {
          label: "Utilisation",
          accessor: "utilisation",
          required: ["3", "4"].indexOf(numeroEtatAvancement) !== -1,
          type: "selectSearch",
          service: UtilisationService.getAll,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          invalidText: "Vous devez sélectionner le type d'utilisation.",
          colSize: 4,
        },
        {
          label: "Type de panne",
          accessor: "type_Panne",
          functionAppliedToValue: (value) => {
            return value ? value.panne : null;
          },
          required:
            ["3", "4"].indexOf(numeroEtatAvancement) !== -1 &&
            this.props.ficheDeSuivi.materiel?.type_Materiel?.designation !=
              "Pandito",
          type: "selectSearch",
          service: () =>
            TypePanneService.getByIdFamilleAffaire(
              !this.props.ficheDeSuivi.materiel?.type_Materiel // source dépend du cas
                ? this.props.ficheDeSuivi.type_Materiel?.id
                : this.props.ficheDeSuivi.materiel?.type_Materiel?.id
            ),
          optionFieldToDisplay: "panne",
          valueFieldToDisplay: "panne",
          setForceUpdateFunction: this.setForceUpdateTypePanne,
          colSize: 4,
        },
        {
          label: "LLD",
          accessor: "materiel.pack",
          colSize: 4,
          functionAppliedToValue: (value) => {
            if (value) return value.designation;
            else return null;
          },
        },
        {
          label: "Garantie",
          accessor: "isUnderGarantie",
          functionAppliedToValue: (value) => {
            if (value != null) return value ? "Oui" : "Non";
            else return null;
          },
          colSize: 4,
        },
        {
          label: "Dernier étalonnage",
          accessor: "lastEtalonnage",
          colSize: 4,
          functionAppliedToValue: (value) => {
            if (value) return ToLocaleDateString(value);
            else return null;
          },
        },

        {
          label: "Matériel de location",
          accessor: "pret",
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
          type: "checkbox",
          colSize: 4,
        },
        ...(this.props.ficheDeSuivi?.pret
          ? [
              {
                label: "Numéro de série",
                accessor: "materielLocation",
                type: "selectSearch",
                colSize: 4,
                service: this.getAllMaterielLocationProxy,
                optionFieldToDisplay: [
                  "num_Serie",
                  "type_Materiel.designation",
                ],
                valueFieldToDisplay: ["num_Serie", "type_Materiel.designation"],
                functionAppliedToValue: (value) => {
                  return value ? value.num_Serie : null;
                },
              },
            ]
          : []),
        ...(this.props.ficheDeSuivi?.pret
          ? [
              {
                label: "Durée du contrat",
                accessor: "dureeContrat",
                type: "decimal",
                handleBlur: this.props.handleChange,
                colSize: 4,
              },
            ]
          : []),
        ...(this.props.ficheDeSuivi?.pret
          ? [
              {
                label: "N° contrat",
                accessor: "numeroContrat",
                type: "text",
                colSize: 4,
              },
            ]
          : []),
      ];

      const infoTest = [
        {
          value:
            this.props.ficheDeSuivi?.resultat_Test === true
              ? {
                  id: 1,
                  display: "Réussi",
                  value: true,
                }
              : this.props.ficheDeSuivi?.resultat_Test === false
              ? {
                  id: 2,
                  display: "Réussi",
                  value: false,
                }
              : null,
          label: "Résultat test",
          accessor: "resultat_Test",
          type: "selectSearch",
          valueFieldToDisplay: "display",
          optionFieldToDisplay: "display",
          optionFieldToReturn: "value",
          options: [
            {
              id: 1,
              display: "Réussi",
              value: true,
            },
            {
              id: 2,
              display: "Échoué",
              value: false,
            },
          ],
          functionAppliedToValue: (value) => {
            if (value != null) return value ? "Réussi" : "Échoué";
            else return null;
          },
          colSize: 4,
        },
        {
          label: "Date test",
          accessor: "date_test",
          type: "date",
          colSize: 4,
        },
        {
          label: "Testeur",
          accessor: "testeur",
          functionAppliedToValue: (value) => {
            return value ? "".concat(value.nom, " ", value.prenom) : null;
          },
          service: PersonnelService.getForSelectSearch,
          optionFieldToDisplay: ["nom", "prenom"],
          valueFieldToDisplay: ["nom", "prenom"],
          type: "selectSearch",
          colSize: 4,
        },
      ];
      const commentaire = [
        {
          accessor: "commentaire",
          type: "textArea",
          colSize: 12,
          functionAppliedToValue: (el) => {
            if (el != null) {
              return <div className="keepWhiteSpace">{el}</div>;
            }
          },
        },
      ];

      const informationsFiche = [
        {
          label: "Date de création",
          accessor: "date_Create",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(value) +
                  " par " +
                  IfNullToText(
                    this.props.ficheDeSuivi.user_Create?.nom,
                    "inconnu"
                  ) +
                  " " +
                  IfNullToText(this.props.ficheDeSuivi.user_Create?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
        {
          label: "Date de modification",
          accessor: "date_Modif",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(value) +
                  " par " +
                  IfNullToText(
                    this.props.ficheDeSuivi.user_Modif?.nom,
                    "inconnu"
                  ) +
                  " " +
                  IfNullToText(this.props.ficheDeSuivi.user_Modif?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
      ];

      return (
        <>
          <div className="row">
            <div className="col-12">
              <BoxInformationsInline
                title="Informations clients"
                datas={informationsClient}
                state={this.props.ficheDeSuivi}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-12">
              <BoxInformationsInline
                title="Fiche"
                datas={prep}
                state={this.props.ficheDeSuivi}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            {!this.state.editingInfMat ? (
              <>
                <div className="col-lg-6 col-md-12">
                  <BoxInformationsInline
                    title="Informations fiche"
                    datas={InformationsFicheDeSuivi}
                    state={this.props.ficheDeSuivi}
                    stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                    service={this.props.service}
                    handleChange={this.props.handleChange}
                    colSize="4"
                    editing={this.props.editing}
                    handleEditing={this.handleEditingFicheMat}
                    NoEdition={right == "RO"}
                  />
                </div>
                {!this.props.ficheDeSuivi?.isAssistance ? (
                  <div className="col-lg-6 col-md-12">
                    <BoxInformationsInline
                      title="Informations matériel"
                      datas={infoMat}
                      state={this.props.ficheDeSuivi}
                      stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                      service={this.props.service}
                      handleChange={this.props.handleChange}
                      colSize="4"
                      editing={this.props.editing}
                      handleEditing={this.handleEditingFicheMat}
                      NoEdition={right == "RO"}
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div
                className={
                  !this.props.ficheDeSuivi?.isAssistance
                    ? "col-lg-12 col-md-12"
                    : "col-lg-6 col-md-12"
                }
              >
                <BoxInformationsInline
                  title="Edition"
                  datas={
                    !this.props.ficheDeSuivi?.isAssistance
                      ? InformationsFicheDeSuivi.concat(infoMat)
                      : InformationsFicheDeSuivi
                  }
                  state={this.props.ficheDeSuivi}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="8"
                  editing={this.props.editing}
                  handleEditing={this.handleEditingFicheMat}
                  handleUpdate={this.props.handleUpdate}
                  handleUndo={this.handleUndo}
                  setForceEdition={true}
                />
              </div>
            )}
            {!this.props.ficheDeSuivi?.isAssistance ? (
              <div className="col-lg-6 col-md-12">
                <BoxInformationsInline
                  title="Test"
                  datas={infoTest}
                  state={this.props.ficheDeSuivi}
                  stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                  service={this.props.service}
                  handleChange={this.props.handleChange}
                  colSize="4"
                  editing={this.props.editing}
                  handleEditing={this.props.handleEditing}
                  NoEdition={right == "RO"}
                />
              </div>
            ) : null}
            <div className="col-lg-6 col-md-12">
              <BoxInformationsInline
                title="Commentaire"
                datas={commentaire}
                state={this.props.ficheDeSuivi}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                editing={this.props.editing}
                handleEditing={this.props.handleEditing}
                NoEdition={right == "RO"}
              />
            </div>
            <div className="col-lg-6 col-md-12 align-self-end">
              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.ficheDeSuivi}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

InformationsFicheDeSuivi.propTypes = {
  ficheDeSuivi: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { InformationsFicheDeSuivi };
