import React from "react";

import { Input } from "_components/Input";
import { customFuseSearch } from "_components/Input/InputTypes/SelectSearchFunctions";

import { faTrash, faCopy, faPaste } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";

import {
  AffaireService,
  HeurePersonnelCompetenceService,
  HeurePersonnelRubriqueService,
} from "_services";

function LigneNewHeure(props) {
  return (
    <tr className={"ligneHeurePersonnel "}>
      <td className="maxWidth150">
        <Input
          accessor={"newHeurePersonnel." + props.heure.id + ".rubrique"}
          value={props.heure.rubrique}
          type="selectSearch"
          placeholder=""
          service={HeurePersonnelRubriqueService.getAll}
          customFilter={(options) => {
            let filtered = options.filter(
              (e) =>
                !(
                  e.name.startsWith("RPSAM") ||
                  e.name.startsWith("RPDIM") ||
                  e.name.startsWith("RPNUI") ||
                  e.name.startsWith("RPFER") ||
                  e.name.startsWith("PLT4") ||
                  e.name.startsWith("PLT5")
                )
            );

            return customFuseSearch(filtered, {
              threshold: 0.4,
              ignoreLocation: true,
            });
          }}
          optionFieldToDisplay={["code", "designation"]}
          valueFieldToDisplay={["code", "designation"]}
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          required={true}
        />
      </td>
      <td>
        <Input
          accessor={"newHeurePersonnel." + props.heure.id + ".competence"}
          value={props.heure.competence}
          type="selectSearch"
          service={HeurePersonnelCompetenceService.getAll}
          optionFieldToDisplay={"designation"}
          valueFieldToDisplay={"designation"}
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
        />
      </td>
      <td>
        <Input
          accessor={"newHeurePersonnel." + props.heure.id + ".affaire"}
          value={props.heure.affaire}
          type="selectSearch"
          placeholder=""
          service={AffaireService.getNonSoldees}
          optionFieldToDisplay={["reference", "nomAffaire"]}
          valueFieldToDisplay={["reference", "nomAffaire"]}
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
          required={true}
        />
      </td>
      <td>
        <Input
          accessor={"newHeurePersonnel." + props.heure.id + ".libelle"}
          value={props.heure.libelle}
          type="text"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
        />
      </td>
      <td className="maxWidth130">
        <Input
          accessor={"newHeurePersonnel." + props.heure.id + ".date"}
          value={props.heure.date}
          type="date"
          placeholder=""
          handleChange={props.handleChange}
          showValidator={false}
          showClearButton={false}
        />
      </td>
      <td className="maxWidth75">
        <Input
          accessor={"newHeurePersonnel." + props.heure.id + ".nombre"}
          value={props.heure.nombre}
          type="decimal"
          placeholder=""
          handleBlur={props.handleChange}
          showValidator={false}
          showClearButton={false}
        />
      </td>
      <td>
        <ButtonIcon
          smallText=""
          icon={faCopy}
          iconSize="sm"
          tooltip="Copier"
          onClick={() => {
            props?.copierLigneHeure(props.heure.id, "creation");
          }}
          className="btn btn-success"
        />
      </td>
      <td>
        <ButtonIcon
          smallText=""
          icon={faPaste}
          iconSize="sm"
          tooltip="Coller"
          onClick={() => {
            props?.collerLigneHeure(props.heure.id, "creation");
          }}
          className="btn btn-success"
        />
      </td>
      <td>
        <ButtonIcon
          smallText=""
          icon={faTrash}
          iconSize="sm"
          onClick={() => {
            props?.deleteLineNewHeurePersonnel(props.heure.id);
          }}
          className="btn btn-danger deleteLineHeurePersonnel"
        />
      </td>
    </tr>
  );
}

export { LigneNewHeure };
