import React from "react";

import * as FileSaver from "file-saver";
import { Helmet } from "react-helmet";
import { AlertDialog, Box, DialogForm, ButtonIcon } from "_components";
import { Input } from "_components/Input";

import {
  ActionsTacheService,
  FamilleAffaireService,
  PersonnelService,
  ProcessTacheService,
  ProduitInterneService,
  TacheService,
} from "_services";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { FiltrePersonnaliseService } from "_services/FiltrePersonnalise";

class RapportActivite extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      errorText: null,
      error: false,
      callBackforceUpdateTypePanne: null,
      callbackForceUpdateContact: null,
      callbackForceUpdateMateriel: null,
      loading: false,
      page: "RapportActivité",
      filtre: {
        nomFiltre: null,
      },
      parametre: {
        dates: null,
        process: null,
        produitInternes: null,
        famille_Affaire: null,
        international: null,
        actions: null,
        personnels: null,
      },
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleNewParameter = this.handleNewParameter.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.personnelProxy = this.personnelProxy.bind(this);
    this.PostFiltreServiceProxy = this.PostFiltreServiceProxy.bind(this);
    this.setOpenDialogFiltre = this.setOpenDialogFiltre.bind(this);
    this.GetFiltreServiceProxy = this.GetFiltreServiceProxy.bind(this);
  }

  async componentDidMount() {}

  handleChange(
    accessor,
    value = "__ACCES_DIRECT__",
    afterUpdateFunction = null
  ) {
    if (value != "__ACCES_DIRECT__") {
      let updatedparametre = { ...this.state.parametre };
      updatedparametre[accessor] = value;
      this.setState({ parametre: updatedparametre }, afterUpdateFunction);
    } else {
      this.setState(
        { parametre: { ...this.state.parametre, ...accessor } },
        afterUpdateFunction
      );
    }
  }

  handleNewParameter(accessor, value) {
    let datestmp = this.state.parametre.dates;

    if (value != null) {
      let newParameter = JSON.parse(value.filtreJSONstringified);
      this.setState({ parametre: { ...newParameter, dates: datestmp } });
    } else {
      this.setState({
        parametre: {
          dates: datestmp,
          process: [],
          produitInternes: [],
          famille_Affaire: null,
          international: null,
          actions: [],
          personnels: [],
        },
      });
    }
  }

  handleSubmit(event) {
    this.setState({ loading: true });
    TacheService.print(this.state.parametre)
      .then((res) => {
        this.setState({ loading: false });

        var blob = new Blob([res?.data], {
          type: "application/pdf;base64",
        });
        FileSaver.saveAs(blob, "rapport.pdf");
      })
      .catch(async (e) => {
        this.setState({ loading: false });
        this.setState({ error: JSON.parse(await e.response.data.text()) });
      });
    event.preventDefault();
  }

  personnelProxy() {
    return new Promise((resolve, reject) =>
      PersonnelService.getAll({ actif: true })
        .then((res) => {
          resolve({ data: res.data?.datas });
        })
        .catch((err) => reject(err))
    );
  }

  setOpenDialogFiltre(openDialogFunc) {
    this.setState({ openDialogFiltreFunc: openDialogFunc });
  }

  PostFiltreServiceProxy() {
    FiltrePersonnaliseService.post({
      ...this.state.filtre,
      page: this.state.page,
      filtreJSONstringified: JSON.stringify({
        ...this.state.parametre,
        dates: null,
      }),
    });
  }

  GetFiltreServiceProxy() {
    return FiltrePersonnaliseService.getByPageAndOid(this.state.page);
  }

  form() {
    const parametres = [
      {
        value: this.state.parametre.dates,
        label: "Dates",
        accessor: "dates",
        type: "period",
        colSize: 16,
        required: true,
      },
      {
        label: "Process",
        value: this.state.parametre.process,
        accessor: "process",
        type: "multipleSelect",
        service: ProcessTacheService.getAll,
        optionFieldToDisplay: "designation",
        colSize: 16,
        required: false,
      },
      {
        label: "Type action",
        value: this.state.parametre.actions,
        accessor: "actions",
        type: "multipleSelect",
        service: ActionsTacheService.getAll,
        optionFieldToDisplay: "designation",
        colSize: 16,
      },
      {
        label: "Produits internes",
        value: this.state.parametre.produitInternes,
        accessor: "produitInternes",
        type: "multipleSelect",
        service: ProduitInterneService.getAll,
        optionFieldToDisplay: "designation",
        colSize: 16,
      },

      {
        value: this.state.parametre.famille_Affaire,
        label: "Famille d'affaires",
        accessor: "famille_Affaire",
        type: "selectSearch",
        service: FamilleAffaireService.getAll,
        optionFieldToDisplay: "designation",
        valueFieldToDisplay: "designation",
        colSize: 16,
      },

      {
        label: "Personnels",
        value: this.state.parametre.personnels,
        accessor: "personnels",
        type: "multipleSelect",
        service: this.personnelProxy,
        optionFieldToDisplay: ["nom", "prenom"],
        valueFieldToDisplay: ["initiales"],
        colSize: 16,
      },
      {
        value: this.state.parametre.international,

        label: "International",
        accessor: "international",
        functionAppliedToValue: (value) => {
          return value ? "Oui" : "Non";
        },
        type: "checkbox",
        colSize: 16,
      },
    ];

    const infos = [{ info: parametres, titre: "Paramètres" }];
    return (
      <form onSubmit={this.handleSubmit}>
        {infos.map((infoItem, index) => {
          return (
            <div className="mb-4" key={index}>
              <Box
                header={
                  <>
                    <div className="col-12 align-self-center py-2 d-flex flex-row justify-content-between">
                      <div>{infoItem.titre}</div>
                      <div className=" a d-flex flex-row ">
                        <Input
                          accessor="parametre"
                          type="selectSearch"
                          service={this.GetFiltreServiceProxy}
                          optionFieldToDisplay="nomFiltre"
                          valueFieldToDisplay="nomFiltre"
                          colSize={12}
                          handleChange={this.handleNewParameter}
                        ></Input>

                        <ButtonIcon
                          icon={faSave}
                          onClick={() => {
                            this.state.openDialogFiltreFunc();
                          }}
                          className="btn btn-success text-light ms-1"
                          style={{ width: "40px" }}
                          iconSize="sm"
                          tooltip="Enregistrer le filtre "
                        ></ButtonIcon>
                      </div>
                    </div>
                  </>
                }
                body={
                  <>
                    {infoItem.info.map((field, fieldsIndex) => {
                      return field ? (
                        <div className="col-lg-4 pt-2" key={fieldsIndex}>
                          <Input
                            label={field.label} // Titre du champ
                            value={field.value} // Valeur du champ
                            accessor={field.accessor} // Accesseur
                            placeholder={field.placeholder}
                            debounce={field.debounce}
                            service={field.service}
                            handleChange={(acc, val) =>
                              this.handleChange(
                                acc,
                                val,
                                field.afterUpdateFunction
                              )
                            } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                            handleBlur={(acc, val) =>
                              this.handleChange(
                                acc,
                                val,
                                field.afterUpdateFunction
                              )
                            } // Fonction pour mettre à jour la valeur dans l'object via son accesseur
                            type={field.type} // Type de l'input a utiliser
                            options={field.options} // options de l'input
                            optionFieldToDisplay={field.optionFieldToDisplay} // champ de l'objet a afficher
                            optionFieldToReturn={field.optionFieldToReturn} // champd de l'objet a utiliser en tant que valeur
                            valueFieldToDisplay={field.valueFieldToDisplay}
                            required={field.required}
                            disabled={field.disabled}
                            validText={field.validText}
                            invalidText={field.invalidText}
                            specialRenderValue={field.specialRenderValue}
                            setForceUpdateFunction={
                              field.setForceUpdateFunction
                            }
                          />
                        </div>
                      ) : null;
                    })}
                  </>
                }
              />
            </div>
          );
        })}

        <div className="text-center">
          <button
            className="btn btn-success mt-4 w-25"
            type="submit"
            disabled={
              this.state.loading || this.state.parametre.dates == null
                ? "disabled"
                : ""
            }
          >
            Générer le fichier
          </button>
        </div>
      </form>
    );
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Génération d'un rapport d'activité</title>
        </Helmet>
        <AlertDialog
          title="Une erreur a été rencontrée"
          body={
            <>
              <div>La génération du rapport a échouée.</div>
              <div>{this.state.error?.cause}</div>
            </>
          }
          valider="Ok"
          open={this.state.error}
          handleClose={() => this.setState({ error: false })}
        />
        <div className="p-3 m-4 " id="rapportActivite">
          <div>
            <DialogForm
              tooltip="Imprimer contrat"
              classNameButton="btn btn-success"
              dialogTitle="Impression"
              labelValidateButton="Valider"
              setOpenDialog={this.setOpenDialogFiltre}
              onValidate={() => {
                this.PostFiltreServiceProxy();
              }}
              onClose={() => {}}
              body={
                <div id="PopupCommande">
                  <div>
                    Veuillez choisir un nom pour ce filtre <br />
                  </div>

                  <Input
                    label="Nom du filtre"
                    accessor="nomFiltre"
                    type="text"
                    value={this.state.filtre.nomFiltre}
                    handleChange={(acc, value) =>
                      this.setState({
                        filtre: {
                          ...this.state.filtre,
                          nomFiltre: value,
                        },
                      })
                    }
                    required={true}
                  />
                </div>
              }
            />
          </div>
          <div className="d-flex overflow-auto justify-content-between py-1">
            <h2 className="nowrap pr-100">
              Génération d'un rapport d'activité
            </h2>
            <div>
              {this.props.history.length > 1 && (
                <button
                  className="btn btn-primary nowrap mx-1"
                  onClick={() => {
                    this.props.history.goBack();
                  }}
                >
                  Retour
                </button>
              )}
            </div>
          </div>
          <div className="row mt-4 mx-1">{this.form()}</div>
        </div>
      </>
    );
  }
}

export { RapportActivite };
