import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { App } from "App/";
import { Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { frFR } from "@mui/material/locale";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
const history = createBrowserHistory();

Sentry.init({
  dsn: "https://573954561ec343739bd5940923db702f@sentry.sol-solution.com/8",
  environment: process.env?.REACT_APP_ENVIRONNMENT,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate:
    process.env?.REACT_APP_ENVIRONNMENT == "development" ? 0 : 1,
  sendDefaultPii: true,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
});
console.log("env : " + process.env?.REACT_APP_ENVIRONNMENT);

export const msalInstance = new PublicClientApplication(msalConfig);

let accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}
const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  frFR
);

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <Router history={history}>
    <ThemeProvider theme={theme}>
      <App instance={msalInstance} historyH={history} />
    </ThemeProvider>
  </Router>
);
