import React from "react";
import { PropTypes } from "prop-types";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { ButtonIcon } from "_components";
import { styled } from "@mui/material/styles";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { getNestedProperty } from "_utils";

const SelectWithMultipleOption = styled(Select)({
  padding: "0px",
  height: "38px",
});

const InputBootstrapLike = styled(Input)({
  input: {
    paddingLeft: "12px",
  },
});

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "50vh",
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getcontentanchorel: null,
};

class MultipleSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectOptions: [],
      selected: this.props.value ? this.props.value : [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.initState = this.initState.bind(this);
    this.getSelectInitialState = this.getSelectInitialState.bind(this);
    this.getOptionFieldToDisplay = this.getOptionFieldToDisplay.bind(this);
    this.getValueFieldToDisplay = this.getValueFieldToDisplay.bind(this);
  }

  componentDidMount() {
    // Si inputOptions a besoin d'une promesse
    Promise.resolve(this.props.service()).then((res) => {
      let values = res;
      if (res.data) {
        values = res.data;
      }
      this.setState({ selectOptions: values }, () => {
        this.initState();
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.value != prevProps.value && Array.isArray(this.props.value))
      this.initState();
  }

  getOptionFieldToDisplay(option) {
    // Si on utilise un objet
    if (this.props.optionFieldToDisplay && option) {
      if (Array.isArray(this.props.optionFieldToDisplay)) {
        // Si on passe un tableau de propriete
        let optionValue = "";
        optionValue = this.props.optionFieldToDisplay
          .map((key) => getNestedProperty(option, key))
          .join(" | ");
        return optionValue.toString();
      } else {
        // Si on passe une seul propriete
        return option[this.props.optionFieldToDisplay]?.toString();
      }
    }
    // Si on utilise un type primitif
    return option ? option : "";
  }

  getValueFieldToDisplay(option) {
    // Si on utilise un objet
    if (this.props.valueFieldToDisplay && option) {
      if (Array.isArray(this.props.valueFieldToDisplay)) {
        // Si on passe un tableau de propriete
        let optionValue = "";
        optionValue = this.props.valueFieldToDisplay
          .map((key) => getNestedProperty(option, key))
          .join(" | ");

        return optionValue.toString();
      } else {
        // Si on passe une seul propriete
        return option[this.props.valueFieldToDisplay]?.toString();
      }
    }

    // Si on utilise un type primitif
    return option ? option.designation : "";
  }

  initState() {
    const initialSelectState = this.getSelectInitialState();
    this.setState({ selected: initialSelectState });
  }

  getSelectInitialState() {
    const initial = this.props.value ? this.props.value : [];

    const initialSelected = this.state.selectOptions.filter((option) => {
      if (initial.find((value) => value.id === option.id)) {
        return true;
      }

      return false;
    });

    return initialSelected;
  }

  handleChange(event) {
    this.setState({ selected: event.target.value }, () => {
      if (this.props.optionFieldToReturn) {
        this.props.handleChange(
          this.props.accessor,
          this.state.selected
            .map((x) => x[this.props.optionFieldToReturn])
            .join("|")
        );
      } else {
        this.props.handleChange(this.props.accessor, this.state.selected);
      }
    });
  }

  isChecked(option) {
    let Checked = false;
    this.state.selected.forEach((value) => {
      if (option.id === value.id) {
        Checked = true;
      }
    });
    return Checked;
  }

  render() {
    return (
      <div>
        {this.props.label ? (
          <div className="text-uppercase text-muted solwayFont">
            {this.props.label}
          </div>
        ) : (
          ""
        )}
        <div className="input-group has-validation">
          <SelectWithMultipleOption
            multiple
            className="form-select multipleSelect"
            value={this.state.selected}
            onChange={this.handleChange}
            input={<InputBootstrapLike />}
            renderValue={(selected) =>
              selected
                .map((item) => this.getValueFieldToDisplay(item))
                .join(", ")
            }
            IconComponent={() => {
              return <></>;
            }}
            required={this.props.required}
            MenuProps={MenuProps}
            aria-describedby={
              "inputGroup" +
              this.props.accessor +
              " validation" +
              this.props.accessor
            }
            disableUnderline
          >
            {this.state.selectOptions
              ? this.state.selectOptions.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option}
                    selected={this.isChecked(option)}
                  >
                    <Checkbox checked={this.isChecked(option)} />
                    <ListItemText
                      primary={this.getOptionFieldToDisplay(option)}
                    />
                  </MenuItem>
                ))
              : null}
          </SelectWithMultipleOption>
          <ButtonIcon
            id={"inputGroup" + this.props.accessor}
            smallText=""
            icon={faTimes}
            iconSize="sm"
            onClick={() => {
              this.setState({ selected: [] });
              this.props.handleChange(this.props.accessor, null);
            }}
            className="btn btn-danger"
            tabIndex="-1"
            style={this.props.disabled ? { display: "none" } : {}}
          ></ButtonIcon>
          <div
            id={"validation" + this.props.accessor}
            className="invalid-feedback"
          >
            {this.props.invalidText}
          </div>
        </div>
      </div>
    );
  }
}

MultipleSelect.propTypes = {
  label: PropTypes.any,
  value: PropTypes.any,
  accessor: PropTypes.any,
  handleChange: PropTypes.any,
  service: PropTypes.any,
  optionFieldToDisplay: PropTypes.any,
  valueFieldToDisplay: PropTypes.any,
  optionFieldToReturn: PropTypes.string,
  required: PropTypes.bool,
  invalidText: PropTypes.string,
};

export { MultipleSelect };
